<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:headerTitle class="text-center">
            <div class="text-center">
              <h5 class="text-white font-weight-bold"><i class="ri-edit-line"></i> {{ $t('irriPumpInformation.pump_report_entry') }}</h5>
            </div>
          </template>
          <template v-slot:body>
            <b-overlay :show="loading">
                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                  <b-form @submit.prevent="handleSubmit(register)" @reset.prevent="reset" >
                    <div class="border border-gray mb-3">
                      <b-row>
                          <b-col lg="12" sm="12" class="mb-4">
                              <h5 class="font-weight-bold text-capitalize text-center bg-success p-1 p-1">{{ $t('irriPumpInformation.Location_of_deep_tube_well') }}</h5>
                          </b-col>
                      </b-row>
                      <b-row class="p-2">
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Organization" vid="org_id" rules="required|min_value:1">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="organization"
                              slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                              {{ $t('org_pro.organization') }} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                plain
                                v-model="pumpReportEntry.org_id"
                                :options="organizationList"
                                id="org_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                  <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                          <ValidationProvider name="Division" rules="required|min_value:1">
                          <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="division_id"
                              slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{ $t('org_pro_division.division')}} <span class="text-danger">*</span>
                                </template>
                              <b-form-select
                              plain
                              v-model="pumpReportEntry.division_id"
                              :options="divisionList"
                              id="division_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                              >
                              <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                              {{ errors[0] }}
                              </div>
                          </b-form-group>
                          </ValidationProvider>
                          <ValidationProvider name="District" rules="required|min_value:1">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="district_id"
                                  slot-scope="{ valid, errors }"
                                  >
                                    <template v-slot:label>
                                      {{ $t('org_pro_district.district')}} <span class="text-danger">*</span>
                                    </template>
                                  <b-form-select
                                  plain
                                  v-model="pumpReportEntry.district_id"
                                  :options="districtList"
                                  id="district_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                          </ValidationProvider>
                          <ValidationProvider name="Upazila" rules="required|min_value:1">
                          <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="upazila_id"
                              slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{ $t('org_pro_upazilla.upazilla')}} <span class="text-danger">*</span>
                                </template>
                              <b-form-select
                              plain
                              v-model="pumpReportEntry.upazilla_id"
                              :options="upazilaList"
                              id="upazila_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                              >
                              <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                              {{ errors[0] }}
                              </div>
                          </b-form-group>
                          </ValidationProvider>
                          <ValidationProvider name="Union" rules="required|min_value:1">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="union_id"
                                  slot-scope="{ valid, errors }"
                                  >
                                    <template v-slot:label>
                                      {{ $t('org_pro_union.union')}} <span class="text-danger">*</span>
                                    </template>
                                  <b-form-select
                                  plain
                                  v-model="pumpReportEntry.union_id"
                                  :options="unionList"
                                  id="union_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Pump Id" vid="pump_id" rules="required|min_value:1">
                              <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="pump_id"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                {{ $t('irriPumpInformation.pump_id') }} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                  plain
                                  v-model="pumpReportEntry.pump_id"
                                  :options="pumpDropdownList"
                                  id="pump_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="Mouza No" vid='mouza_no' rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="mouza_no"
                                  slot-scope="{ valid, errors }"
                              >
                                  <template v-slot:label>
                                    {{ $t('irriPumpInformation.mouza_no')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-input
                                  id="mouza_no"
                                  v-model="pumpReportEntry.mouza_no"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  disabled
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                          </ValidationProvider>
                            <ValidationProvider name="Jl No" vid='jl_no' rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="jl_no"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{ $t('irriPumpInformation.jl_no')}} <span class="text-danger">*</span>
                              </template>
                                <b-form-input
                                id="jl_no"
                                v-model="pumpReportEntry.jl_no"
                                :state="errors[0] ? false : (valid ? true : null)"
                                disabled
                                ></b-form-input>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="Plot No" vid='plot_no' rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="plot_no"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{ $t('irriPumpInformation.plot_no')}} <span class="text-danger">*</span>
                              </template>
                                <b-form-input
                                id="plot_no"
                                v-model="pumpReportEntry.plot_no"
                                :state="errors[0] ? false : (valid ? true : null)"
                                disabled
                                ></b-form-input>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                            </ValidationProvider>
                        </b-col>
                      </b-row>
                    </div>
                    <b-row>
                      <b-col lg="6" sm="12">
                        <ValidationProvider name="form type" vid="report_type_id" rules="required|min_value:1">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="report_type_id"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                            {{ $t('irriPumpInformation.report_type') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                              plain
                              v-model="pumpReportEntry.report_type_id"
                              :options="reportTypeList"
                              id="report_type_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                              >
                              <template v-slot:first>
                                <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                    </b-row>
                    <div class="border border-gray mb-3">
                      <b-row>
                        <b-col lg="12" sm="12" class="mb-4">
                          <h5 class="font-weight-bold text-capitalize text-center bg-success p-1">{{ $t('irriPumpInformation.deep_tube_well_installation') }}</h5>
                        </b-col>
                      </b-row>
                      <b-row class="p-2">
                        <b-col lg="4" sm="12">
                          <ValidationProvider name="Date Of Excavation" vid="install_date_of_excavation">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="install_date_of_excavation"
                              slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{ $t('irriPumpInformation.date_of_excavation') }}
                            </template>
                            <b-form-input class="form-control"
                              v-model="pumpReportEntry.install_date_of_excavation"
                              :placeholder="$t('globalTrans.select_date')"
                              id="datepicker"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="4" sm="12">
                          <ValidationProvider name="Date Of Commission" vid="install_date_of_commission">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="install_date_of_commission"
                              slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{ $t('irriPumpInformation.date_of_commission') }}
                            </template>
                            <b-form-input class="form-control"
                              v-model="pumpReportEntry.install_date_of_commission"
                              :placeholder="$t('globalTrans.select_date')"
                              id="datepicker"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="4" sm="12">
                          <ValidationProvider name="Accepted Participation" vid="install_accepted_participation">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="install_accepted_participation"
                              slot-scope="{ valid, errors }"
                              style="font-size: 13px"
                            >
                            <template v-slot:label>
                              {{ $t('irriPumpInformation.accepted_participation') }}
                            </template>
                            <b-form-input
                              id="accepted_participation"
                              v-model="pumpReportEntry.install_accepted_participation"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                      </b-row>
                    </div>
                    <div class="border border-gray mb-3">
                      <b-row>
                        <b-col lg="12" sm="12" class="mb-4">
                          <h5 class="font-weight-bold text-capitalize text-center bg-success p-1">{{ $t('irriPumpInformation.deep_tube_well_rehabilitation') }}</h5>
                        </b-col>
                      </b-row>
                      <b-row class="p-2">
                        <b-col lg="4" sm="12">
                          <ValidationProvider name="Date Of Excavation" vid="rehab_date_of_excavation">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="rehab_date_of_excavation"
                              slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{ $t('irriPumpInformation.date_of_excavation') }}
                            </template>
                            <b-form-input class="form-control"
                              v-model="pumpReportEntry.rehab_date_of_excavation"
                              :placeholder="$t('globalTrans.select_date')"
                              id="datepicker"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="4" sm="12">
                          <ValidationProvider name="Date Of Commission" vid="rehab_date_of_commission">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="rehab_date_of_commission"
                              slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{ $t('irriPumpInformation.date_of_commission') }}
                            </template>
                            <b-form-input class="form-control"
                              v-model="pumpReportEntry.rehab_date_of_commission"
                              :placeholder="$t('globalTrans.select_date')"
                              id="datepicker"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="4" sm="12">
                          <ValidationProvider name="Accepted Participation" vid="rehab_accepted_participation">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="rehab_accepted_participation"
                              slot-scope="{ valid, errors }"
                              style="font-size: 13px"
                            >
                            <template v-slot:label>
                              {{ $t('irriPumpInformation.accepted_participation') }}
                            </template>
                            <b-form-input
                              id="accepted_participation"
                              v-model="pumpReportEntry.rehab_accepted_participation"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                      </b-row>
                    </div>
                    <b-row>
                      <b-col lg="4" sm="12">
                        <ValidationProvider name="Project Name" vid="project_name">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="project_name"
                            slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                            {{ $t('irriPumpInformation.project_name') }}
                          </template>
                          <b-form-input
                            v-model="pumpReportEntry.project_name"
                            id="project_name"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="4" sm="12">
                        <ValidationProvider name="Deep Tube Well Nature" vid="deep_tube_well_nature">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="deep_tube_well_nature"
                            slot-scope="{ valid, errors }"
                            style="font-size: 13px"
                          >
                          <template v-slot:label>
                            {{ $t('irriPumpInformation.deep_tube_well_nature') }}
                          </template>
                          <b-form-input
                            id="deep_tube_well_nature"
                            v-model="pumpReportEntry.deep_tube_well_nature"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="4" sm="12">
                        <ValidationProvider name="Operator Name" vid="operator_name">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="operator_name"
                            slot-scope="{ valid, errors }"
                            style="font-size: 13px"
                          >
                          <template v-slot:label>
                            {{ $t('irriPumpInformation.operator_name') }}
                          </template>
                          <b-form-input
                            id="operator_name"
                            v-model="pumpReportEntry.operator_name"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                    </b-row>
                    <div class="border border-gray mb-3">
                      <b-row>
                        <b-col lg="12" sm="12" class="mb-4">
                          <h5 class="font-weight-bold text-capitalize text-center bg-success p-1">{{ $t('irriPumpInformation.information_on_goods') }}</h5>
                        </b-col>
                      </b-row>
                      <b-row class="p-2">
                        <b-col lg="12" sm="12" class="mb-4">
                          <h5 class="font-weight-bold text-capitalize">{{ $t('irriPumpInformation.housing_pipe') }}</h5>
                        </b-col>
                      </b-row>
                      <b-row class="p-2">
                        <b-col lg="4" sm="12">
                          <ValidationProvider name="MS" vid="ms">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="ms"
                              slot-scope="{ valid, errors }"
                              style="font-size: 13px"
                            >
                            <template v-slot:label>
                              {{ $t('irriPumpInformation.ms') }}
                            </template>
                            <b-form-input
                              id="ms"
                              v-model="pumpReportEntry.ms"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="4" sm="12">
                          <ValidationProvider name="FG" vid="fg">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="fg"
                              slot-scope="{ valid, errors }"
                              style="font-size: 13px"
                            >
                            <template v-slot:label>
                              {{ $t('irriPumpInformation.fg') }}
                            </template>
                            <b-form-input
                              id="fg"
                              v-model="pumpReportEntry.fg"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="4" sm="12">
                          <ValidationProvider name="UPDC" vid="updc">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="updc"
                              slot-scope="{ valid, errors }"
                              style="font-size: 13px"
                            >
                            <template v-slot:label>
                              {{ $t('irriPumpInformation.updc') }}
                            </template>
                            <b-form-input
                              id="updc"
                              v-model="pumpReportEntry.updc"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                      </b-row>
                      <b-row class="p-2">
                        <b-col lg="12" sm="12" class="mb-4">
                          <h5 class="font-weight-bold text-capitalize">{{ $t('irriPumpInformation.filter') }}</h5>
                        </b-col>
                      </b-row>
                      <b-row class="p-2">
                        <b-col lg="4" sm="12">
                          <ValidationProvider name="Filter Ms" vid="filter_ms">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="filter_ms"
                              slot-scope="{ valid, errors }"
                              style="font-size: 13px"
                            >
                            <template v-slot:label>
                              {{ $t('irriPumpInformation.filter_ms') }}
                            </template>
                            <b-form-input
                              id="filter_ms"
                              v-model="pumpReportEntry.filter_ms"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="4" sm="12">
                          <ValidationProvider name="Filter Fg" vid="filter_fg">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="filter_fg"
                              slot-scope="{ valid, errors }"
                              style="font-size: 13px"
                            >
                            <template v-slot:label>
                              {{ $t('irriPumpInformation.filter_fg') }}
                            </template>
                            <b-form-input
                              id="filter_fg"
                              v-model="pumpReportEntry.filter_fg"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="4" sm="12">
                          <ValidationProvider name="Filter UPDC" vid="filter_updc">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="filter_updc"
                              slot-scope="{ valid, errors }"
                              style="font-size: 13px"
                            >
                            <template v-slot:label>
                              {{ $t('irriPumpInformation.updc') }}
                            </template>
                            <b-form-input
                              id="filter_updc"
                              v-model="pumpReportEntry.filter_updc"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                      </b-row>
                      <b-row class="p-2">
                        <b-col lg="12" sm="12" class="mb-4">
                          <h5 class="font-weight-bold text-capitalize">{{ $t('irriPumpInformation.installed_column_pipe') }}</h5>
                        </b-col>
                      </b-row>
                      <b-row class="p-2">
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="DIA" vid="dia">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="dia"
                              slot-scope="{ valid, errors }"
                              style="font-size: 13px"
                            >
                            <template v-slot:label>
                              {{ $t('irriPumpInformation.dia') }}
                            </template>
                            <b-form-input
                              id="dia"
                              v-model="pumpReportEntry.dia"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Amount" vid="amount">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="amount"
                              slot-scope="{ valid, errors }"
                              style="font-size: 13px"
                            >
                            <template v-slot:label>
                              {{ $t('irriPumpInformation.amount') }}
                            </template>
                            <b-form-input
                              type="number"
                              min="0"
                              id="amount"
                              v-model="pumpReportEntry.amount"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                      </b-row>
                    </div>
                    <div class="border border-gray mb-3">
                      <b-row>
                        <b-col lg="12" sm="12" class="mb-4">
                          <h5 class="font-weight-bold text-capitalize text-center bg-success p-1">{{ $t('irriPumpInformation.technical_details') }}</h5>
                        </b-col>
                      </b-row>
                      <b-row class="p-2">
                        <b-col lg="12" sm="12" class="mb-4">
                          <h5 class="font-weight-bold text-capitalize">{{ $t('irriPumpInformation.vertical') }}</h5>
                        </b-col>
                      </b-row>
                      <b-row class="p-2">
                        <b-col lg="4" sm="12">
                          <ValidationProvider name="Vertical Make O Model" vid="vertical_make_o_model">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="vertical_make_o_model"
                              slot-scope="{ valid, errors }"
                              style="font-size: 13px"
                            >
                            <template v-slot:label>
                              {{ $t('irriPumpInformation.make_o_model') }}
                            </template>
                            <b-form-input
                              id="vertical_make_o_model"
                              v-model="pumpReportEntry.vertical_make_o_model"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="4" sm="12">
                          <ValidationProvider name="Vertical Unit Consumption" vid="vertical_unit_consumption">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="vertical_unit_consumption"
                              slot-scope="{ valid, errors }"
                              style="font-size: 13px"
                            >
                            <template v-slot:label>
                              {{ $t('irriPumpInformation.unit_consumption') }}
                            </template>
                            <b-form-input
                              id="vertical_unit_consumption"
                              v-model="pumpReportEntry.vertical_unit_consumption"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="4" sm="12">
                          <ValidationProvider name="Vertical Power" vid="vertial_power">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="vertial_power"
                              slot-scope="{ valid, errors }"
                              style="font-size: 13px"
                            >
                            <template v-slot:label>
                              {{ $t('irriPumpInformation.power') }}
                            </template>
                            <b-form-input
                              id="vertial_power"
                              v-model="pumpReportEntry.vertical_power"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                      </b-row>
                      <b-row class="p-2">
                        <b-col lg="12" sm="12" class="mb-4">
                          <h5 class="font-weight-bold text-capitalize">{{ $t('irriPumpInformation.turbine_submersible_motor') }}</h5>
                        </b-col>
                      </b-row>
                      <b-row class="p-2">
                        <b-col lg="4" sm="12">
                          <ValidationProvider name="Turbine Make O Model" vid="turbine_make_o_model">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="turbine_make_o_model"
                              slot-scope="{ valid, errors }"
                              style="font-size: 13px"
                            >
                            <template v-slot:label>
                              {{ $t('irriPumpInformation.make_o_model') }}
                            </template>
                            <b-form-input
                              id="turbine_make_o_model"
                              v-model="pumpReportEntry.turbine_make_o_model"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="4" sm="12">
                          <ValidationProvider name="Turbine Unit Consumption" vid="turbine_unit_consumption">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="turbine_unit_consumption"
                              slot-scope="{ valid, errors }"
                              style="font-size: 13px"
                            >
                            <template v-slot:label>
                              {{ $t('irriPumpInformation.unit_consumption') }}
                            </template>
                            <b-form-input
                              id="turbine_unit_consumption"
                              v-model="pumpReportEntry.turbine_unit_consumption"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="4" sm="12">
                          <ValidationProvider name="Turbine Power" vid="turbine_power">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="turbine_power"
                              slot-scope="{ valid, errors }"
                              style="font-size: 13px"
                            >
                            <template v-slot:label>
                              {{ $t('irriPumpInformation.power') }}
                            </template>
                            <b-form-input
                              id="turbine_power"
                              v-model="pumpReportEntry.turbine_power"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                      </b-row>
                      <b-row class="p-2">
                          <b-col lg="4" sm="12">
                            <ValidationProvider name="Head" vid="head">
                                <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="head"
                                slot-scope="{ valid, errors }"
                                style="font-size: 13px"
                                >
                                <template v-slot:label>
                                {{ $t('irriPumpInformation.head') }}
                                </template>
                                <b-form-input
                                id="head"
                                v-model="pumpReportEntry.head"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                                </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="4" sm="12">
                            <ValidationProvider name="Discharge" vid="discharge">
                                <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="discharge"
                                slot-scope="{ valid, errors }"
                                style="font-size: 13px"
                                >
                                <template v-slot:label>
                                {{ $t('irriPumpInformation.discharge') }}
                                </template>
                                <b-form-input
                                id="discharge"
                                v-model="pumpReportEntry.discharge"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                                </b-form-group>
                            </ValidationProvider>
                          </b-col>
                      </b-row>
                      <b-row class="p-2">
                        <b-col lg="12" sm="12" class="mt-3">
                          <ValidationProvider name="Command Area" vid="command_area">
                            <b-form-group
                              class="row"
                              label-cols-sm="2"
                              label-for="command_area"
                              slot-scope="{ valid, errors }"
                              style="font-size: 13px"
                            >
                            <template v-slot:label>
                              <h6>{{ $t('irriPumpInformation.command_area') }}</h6>
                            </template>
                            <b-form-input
                              id="command_area"
                              v-model="pumpReportEntry.command_area"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                      </b-row>
                    </div>
                    <div class="border border-gray mb-3">
                      <b-row>
                        <b-col lg="12" sm="12" class="mb-4">
                          <h5 class="font-weight-bold text-capitalize text-center bg-success p-1">{{ $t('irriPumpInformation.amount_irrigated_land') }}</h5>
                        </b-col>
                      </b-row>
                      <b-row class="p-2">
                        <b-col lg="12" sm="12" class="mb-4">
                          <h5 class="font-weight-bold text-capitalize">{{ $t('irriPumpInformation.kharif_1') }}</h5>
                        </b-col>
                      </b-row>
                      <b-row class="p-2">
                        <b-col lg="3" sm="12">
                          <ValidationProvider name="Kharif 1 Aus" vid="kharif_1_aus">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="kharif_1_aus"
                              slot-scope="{ valid, errors }"
                              style="font-size: 13px"
                            >
                            <template v-slot:label>
                              {{ $t('irriPumpInformation.aus') }}
                            </template>
                            <b-form-input
                              id="kharif_1_aus"
                              v-model="pumpReportEntry.kharif_1_aus"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="3" sm="12">
                          <ValidationProvider name="Kharif 1 Others" vid="kharif_1_others">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="kharif_1_others"
                              slot-scope="{ valid, errors }"
                              style="font-size: 13px"
                            >
                            <template v-slot:label>
                              {{ $t('irriPumpInformation.others') }}
                            </template>
                            <b-form-input
                              id="kharif_1_others"
                              v-model="pumpReportEntry.kharif_1_others"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="3" sm="12">
                          <ValidationProvider name="Kharif 1 Total" vid="kharif_1_total">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="kharif_1_total"
                              slot-scope="{ valid, errors }"
                              style="font-size: 13px"
                            >
                            <template v-slot:label>
                              {{ $t('irriPumpInformation.total') }}
                            </template>
                            <b-form-input
                              readonly
                              id="kharif_1_total"
                              v-model="pumpReportEntry.kharif_1_total"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="3" sm="12">
                          <ValidationProvider name="kharif 1 aus per hector cost" vid="kharif_1_aus_per_hector_cost">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="kharif_1_aus_per_hector_cost"
                              slot-scope="{ valid, errors }"
                              style="font-size: 13px"
                            >
                            <template v-slot:label>
                              {{ $t('irriPumpInformation.per_hector_cost') }}
                            </template>
                            <b-form-input
                              type="number"
                              min="0"
                              id="kharif_1_aus_per_hector_cost"
                              v-model="pumpReportEntry.kharif_1_aus_per_hector_cost"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                      </b-row>
                      <b-row class="p-2">
                        <b-col lg="12" sm="12" class="mb-4">
                          <h5 class="font-weight-bold text-capitalize">{{ $t('irriPumpInformation.kharif_2') }}</h5>
                        </b-col>
                      </b-row>
                      <b-row class="p-2">
                        <b-col lg="3" sm="12">
                          <ValidationProvider name="Kharif 2 Aman" vid="kharif_2_aman">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="kharif_2_aman"
                              slot-scope="{ valid, errors }"
                              style="font-size: 13px"
                            >
                            <template v-slot:label>
                              {{ $t('irriPumpInformation.aman') }}
                            </template>
                            <b-form-input
                              id="kharif_2_aman"
                              v-model="pumpReportEntry.kharif_2_aman"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="3" sm="12">
                          <ValidationProvider name="Kharif 2 Others" vid="kharif_2_others">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="kharif_2_others"
                              slot-scope="{ valid, errors }"
                              style="font-size: 13px"
                            >
                            <template v-slot:label>
                              {{ $t('irriPumpInformation.others') }}
                            </template>
                            <b-form-input
                              id="kharif_2_others"
                              v-model="pumpReportEntry.kharif_2_others"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="3" sm="12">
                          <ValidationProvider name="Kharif 2 Total" vid="kharif_2_total">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="kharif_2_total"
                              slot-scope="{ valid, errors }"
                              style="font-size: 13px"
                            >
                            <template v-slot:label>
                              {{ $t('irriPumpInformation.total') }}
                            </template>
                            <b-form-input
                              readonly
                              id="kharif_2_total"
                              v-model="pumpReportEntry.kharif_2_total"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="3" sm="12">
                          <ValidationProvider name="Kharif 2 per hector cost" vid="kharif_2_aman_per_hector_cost">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="kharif_2_aman_per_hector_cost"
                              slot-scope="{ valid, errors }"
                              style="font-size: 13px"
                            >
                            <template v-slot:label>
                              {{ $t('irriPumpInformation.per_hector_cost') }}
                            </template>
                            <b-form-input
                              type="number"
                              min="0"
                              id="kharif_2_aman_per_hector_cost"
                              v-model="pumpReportEntry.kharif_2_aman_per_hector_cost"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                      </b-row>
                      <b-row class="p-2">
                        <b-col lg="12" sm="12" class="mb-4">
                          <h5 class="font-weight-bold text-capitalize">{{ $t('irriPumpInformation.robi') }}</h5>
                        </b-col>
                      </b-row>
                      <b-row class="p-2">
                          <b-col lg="3" sm="12">
                            <ValidationProvider name="Borou" vid="borou">
                                <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="borou"
                                slot-scope="{ valid, errors }"
                                style="font-size: 13px"
                                >
                                <template v-slot:label>
                                {{ $t('irriPumpInformation.borou') }}
                                </template>
                                <b-form-input
                                  id="borou"
                                  v-model="pumpReportEntry.borou"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                                </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="3" sm="12">
                            <ValidationProvider name="borou per hector cost" vid="borou_per_hector_cost">
                                <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="borou_per_hector_cost"
                                slot-scope="{ valid, errors }"
                                style="font-size: 13px"
                                >
                                <template v-slot:label>
                                {{ $t('irriPumpInformation.per_hector_cost') }}
                                </template>
                                <b-form-input
                                  type="number"
                                  min="0"
                                  id="borou_per_hector_cost"
                                  v-model="pumpReportEntry.borou_per_hector_cost"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                                </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="3" sm="12">
                            <ValidationProvider name="Wheat" vid="wheat">
                                <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="wheat"
                                slot-scope="{ valid, errors }"
                                style="font-size: 13px"
                                >
                                <template v-slot:label>
                                {{ $t('irriPumpInformation.wheat') }}
                                </template>
                                <b-form-input
                                id="wheat"
                                v-model="pumpReportEntry.wheat"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                                </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="3" sm="12">
                            <ValidationProvider name="wheat per hector cost" vid="wheat_per_hector_cost">
                                <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="wheat_per_hector_cost"
                                slot-scope="{ valid, errors }"
                                style="font-size: 13px"
                                >
                                <template v-slot:label>
                                {{ $t('irriPumpInformation.per_hector_cost') }}
                                </template>
                                <b-form-input
                                  type="number"
                                  min="0"
                                  id="wheat_per_hector_cost"
                                  v-model="pumpReportEntry.wheat_per_hector_cost"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                                </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="3" sm="12">
                            <ValidationProvider name="Potato" vid="potato">
                                <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="potato"
                                slot-scope="{ valid, errors }"
                                style="font-size: 13px"
                                >
                                <template v-slot:label>
                                {{ $t('irriPumpInformation.potato') }}
                                </template>
                                <b-form-input
                                id="potato"
                                v-model="pumpReportEntry.potato"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                                </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="3" sm="12">
                            <ValidationProvider name="potato per hector cost" vid="potato_per_hector_cost">
                                <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="potato_per_hector_cost"
                                slot-scope="{ valid, errors }"
                                style="font-size: 13px"
                                >
                                <template v-slot:label>
                                {{ $t('irriPumpInformation.per_hector_cost') }}
                                </template>
                                <b-form-input
                                  type="number"
                                  min="0"
                                  id="potato_per_hector_cost"
                                  v-model="pumpReportEntry.potato_per_hector_cost"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                                </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="3" sm="12">
                            <ValidationProvider name="Corn" vid="corn">
                                <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="corn"
                                slot-scope="{ valid, errors }"
                                style="font-size: 13px"
                                >
                                <template v-slot:label>
                                {{ $t('irriPumpInformation.corn') }}
                                </template>
                                <b-form-input
                                id="corn"
                                v-model="pumpReportEntry.corn"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                                </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="3" sm="12">
                            <ValidationProvider name="vegetables per hector cost" vid="corn_per_hector_cost">
                                <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="corn_per_hector_cost"
                                slot-scope="{ valid, errors }"
                                style="font-size: 13px"
                                >
                                <template v-slot:label>
                                {{ $t('irriPumpInformation.per_hector_cost') }}
                                </template>
                                <b-form-input
                                  type="number"
                                  min="0"
                                  id="corn_per_hector_cost"
                                  v-model="pumpReportEntry.corn_per_hector_cost"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                                </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="3" sm="12">
                            <ValidationProvider name="Mustard" vid="mustard">
                                <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="mustard"
                                slot-scope="{ valid, errors }"
                                style="font-size: 13px"
                                >
                                <template v-slot:label>
                                {{ $t('irriPumpInformation.mustard') }}
                                </template>
                                <b-form-input
                                id="mustard"
                                v-model="pumpReportEntry.mustard"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                                </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="3" sm="12">
                            <ValidationProvider name="mustard per hector cost" vid="mustard_per_hector_cost">
                                <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="mustard_per_hector_cost"
                                slot-scope="{ valid, errors }"
                                style="font-size: 13px"
                                >
                                <template v-slot:label>
                                {{ $t('irriPumpInformation.per_hector_cost') }}
                                </template>
                                <b-form-input
                                  type="number"
                                  min="0"
                                  id="mustard_per_hector_cost"
                                  v-model="pumpReportEntry.mustard_per_hector_cost"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                                </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="3" sm="12">
                            <ValidationProvider name="Lentils" vid="lentils">
                                <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="lentils"
                                slot-scope="{ valid, errors }"
                                style="font-size: 13px"
                                >
                                <template v-slot:label>
                                {{ $t('irriPumpInformation.lentils') }}
                                </template>
                                <b-form-input
                                id="lentils"
                                v-model="pumpReportEntry.lentils"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                                </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="3" sm="12">
                            <ValidationProvider name="lentils per hector cost" vid="lentils_per_hector_cost">
                                <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="lentils_per_hector_cost"
                                slot-scope="{ valid, errors }"
                                style="font-size: 13px"
                                >
                                <template v-slot:label>
                                {{ $t('irriPumpInformation.per_hector_cost') }}
                                </template>
                                <b-form-input
                                  type="number"
                                  min="0"
                                  id="lentils_per_hector_cost"
                                  v-model="pumpReportEntry.lentils_per_hector_cost"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                                </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="3" sm="12">
                            <ValidationProvider name="Vegetables" vid="vegetables">
                                <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="vegetables"
                                slot-scope="{ valid, errors }"
                                style="font-size: 13px"
                                >
                                <template v-slot:label>
                                {{ $t('irriPumpInformation.vegetables') }}
                                </template>
                                <b-form-input
                                id="vegetables"
                                v-model="pumpReportEntry.vegetables"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                                </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="3" sm="12">
                            <ValidationProvider name="vegetables per hector cost" vid="vegetables_per_hector_cost">
                                <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="vegetables_per_hector_cost"
                                slot-scope="{ valid, errors }"
                                style="font-size: 13px"
                                >
                                <template v-slot:label>
                                {{ $t('irriPumpInformation.per_hector_cost') }}
                                </template>
                                <b-form-input
                                  type="number"
                                  min="0"
                                  id="vegetables_per_hector_cost"
                                  v-model="pumpReportEntry.vegetables_per_hector_cost"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                                </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="3" sm="12">
                            <ValidationProvider name="Robi Total" vid="robi_total">
                                <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="robi_total"
                                slot-scope="{ valid, errors }"
                                style="font-size: 13px"
                                >
                                <template v-slot:label>
                                {{ $t('irriPumpInformation.total') + ' ' + $t('irriPumpInformation.hector') }}
                                </template>
                                <b-form-input
                                readonly
                                id="robi_total"
                                v-model="pumpReportEntry.robi_total"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                                </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="3" sm="12">
                            <ValidationProvider name="Robi Total" vid="other_scheme_area">
                                <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="other_scheme_area"
                                slot-scope="{ valid, errors }"
                                style="font-size: 13px"
                                >
                                <template v-slot:label>
                                {{ $t('irriPumpInformation.other_scheme_area') }}
                                </template>
                                <b-form-input
                                type="number"
                                min="0"
                                id="other_scheme_area"
                                v-model="pumpReportEntry.other_scheme_area"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                                </b-form-group>
                            </ValidationProvider>
                          </b-col>
                      </b-row>
                    </div>
                    <div class="border border-gray mb-3">
                      <b-row>
                        <b-col lg="12" sm="12" class="mb-4">
                          <h5 class="font-weight-bold text-capitalize text-center bg-success p-1">{{ $t('irriPumpInformation.beneficial_farmer') }}</h5>
                        </b-col>
                      </b-row>
                      <b-row class="p-2">
                        <b-col lg="4" sm="12">
                          <ValidationProvider name="Actual" vid="actual">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="actual"
                              slot-scope="{ valid, errors }"
                              style="font-size: 13px"
                            >
                            <template v-slot:label>
                              {{ $t('irriPumpInformation.actual') }}
                            </template>
                            <b-form-input
                              type="number"
                              min="0"
                              id="actual"
                              v-model="pumpReportEntry.actual"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="4" sm="12">
                          <ValidationProvider name="Barga" vid="barga">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="barga"
                              slot-scope="{ valid, errors }"
                              style="font-size: 13px"
                            >
                            <template v-slot:label>
                              {{ $t('irriPumpInformation.barga') }}
                            </template>
                            <b-form-input
                              type="number"
                              min="0"
                              id="barga"
                              v-model="pumpReportEntry.barga"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="4" sm="12">
                          <ValidationProvider name="Beneficial Farmer Total" vid="beneficial_farmer_total">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="beneficial_farmer_total"
                              slot-scope="{ valid, errors }"
                              style="font-size: 13px"
                            >
                            <template v-slot:label>
                              {{ $t('irriPumpInformation.total') }}
                            </template>
                            <b-form-input
                              type="number"
                              min="0"
                              id="beneficial_farmer_total"
                              v-model="pumpReportEntry.beneficial_farmer_total"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                      </b-row>
                    </div>
                    <div class="border border-gray mb-3">
                      <b-row>
                        <b-col lg="12" sm="12" class="mb-4">
                          <h5 class="font-weight-bold text-capitalize text-center bg-success p-1">{{ $t('irriPumpInformation.tot_uses_unit') }}</h5>
                        </b-col>
                      </b-row>
                      <b-row class="p-2">
                        <b-col lg="4" sm="12">
                          <ValidationProvider name="Start reading" vid="start_reading">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="start_reading"
                              slot-scope="{ valid, errors }"
                              style="font-size: 13px"
                            >
                            <template v-slot:label>
                              {{ $t('irriPumpInformation.start_reading') }}
                            </template>
                            <b-form-input
                              type="number"
                              min="0"
                              id="start_reading"
                              v-model="pumpReportEntry.start_reading"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="4" sm="12">
                        <ValidationProvider name="End reading" vid="end_reading">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="end_reading"
                              slot-scope="{ valid, errors }"
                              style="font-size: 12px"
                            >
                            <template v-slot:label>
                              {{ $t('irriPumpInformation.end_reading') }}
                            </template>
                            <b-form-input
                              type="number"
                              min="0"
                              id="end_reading"
                              v-model="pumpReportEntry.end_reading"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="4" sm="12">
                          <ValidationProvider name="Total uses unit" vid="total_uses_unit">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="total_uses_unit"
                              slot-scope="{ valid, errors }"
                              style="font-size: 13px"
                            >
                            <template v-slot:label>
                              {{ $t('irriPumpInformation.total') }}
                            </template>
                            <b-form-input
                              type="number"
                              min="0"
                              id="total_uses_unit"
                              v-model="pumpReportEntry.total_uses_unit"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                      </b-row>
                    </div>
                    <div class="border border-gray mb-3">
                      <b-row>
                        <b-col lg="12" sm="12" class="mb-4">
                          <h5 class="font-weight-bold text-capitalize text-center bg-success p-1">{{ $t('irriPumpInformation.irri_adai_descrip') }}</h5>
                        </b-col>
                      </b-row>
                      <b-row class="p-2">
                        <b-col lg="4" sm="12">
                          <ValidationProvider name="Hourly used unit" vid="hourly_used_unit">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="hourly_used_unit"
                              slot-scope="{ valid, errors }"
                              style="font-size: 13px"
                            >
                            <template v-slot:label>
                              {{ $t('irriPumpInformation.hourly_used_unit') }}
                            </template>
                            <b-form-input
                              id="hourly_used_unit"
                              v-model="pumpReportEntry.hourly_used_unit"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="4" sm="12">
                          <ValidationProvider name="Total active hour" vid="total_active_hour">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="total_active_hour"
                              slot-scope="{ valid, errors }"
                              style="font-size: 13px"
                            >
                            <template v-slot:label>
                              {{ $t('irriPumpInformation.tot_active_hour') }}
                            </template>
                            <b-form-input
                              id="total_active_hour"
                              v-model="pumpReportEntry.total_active_hour"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="4" sm="12">
                          <ValidationProvider name="Hourly irrigation charge" vid="hourly_irri_charge">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="hourly_irri_charge"
                              slot-scope="{ valid, errors }"
                              style="font-size: 13px"
                            >
                            <template v-slot:label>
                              {{ $t('irriPumpInformation.hourly_irri_charge') }}
                            </template>
                            <b-form-input
                              id="hourly_irri_charge"
                              v-model="pumpReportEntry.hourly_irri_charge"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                          </b-col>
                      </b-row>
                      <b-row class="p-2">
                        <b-col lg="4" sm="12">
                          <ValidationProvider name="Recoverable irrigation payment" vid="recoverable_irri_payment">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="recoverable_irri_payment"
                              slot-scope="{ valid, errors }"
                              style="font-size: 13px"
                            >
                            <template v-slot:label>
                              {{ $t('irriPumpInformation.recoverable_irri_payment') }}
                            </template>
                            <b-form-input
                              id="recoverable_irri_payment"
                              v-model="pumpReportEntry.recoverable_irri_payment"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="4" sm="12">
                          <ValidationProvider name="Collected irrigation payment" vid="collected_irri_payment">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="collected_irri_payment"
                              slot-scope="{ valid, errors }"
                              style="font-size: 13px"
                            >
                            <template v-slot:label>
                              {{ $t('irriPumpInformation.collected_irri_payment') }}
                            </template>
                            <b-form-input
                              id="collected_irri_payment"
                              v-model="pumpReportEntry.collected_irri_payment"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="4" sm="12">
                          <ValidationProvider name="Unpaid money" vid="unpaid_money">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="unpaid_money"
                              slot-scope="{ valid, errors }"
                              style="font-size: 13px"
                            >
                            <template v-slot:label>
                              {{ $t('irriPumpInformation.unpaid_money') }}
                            </template>
                            <b-form-input
                              id="unpaid_money"
                              v-model="pumpReportEntry.unpaid_money"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                      </b-row>
                    </div>
                    <div class="border border-gray mb-3">
                      <b-row>
                        <b-col lg="12" sm="12" class="mb-4">
                          <h5 class="font-weight-bold text-capitalize text-center bg-success p-1">{{ $t('irriPumpInformation.mnge_ment_cost') }}</h5>
                        </b-col>
                      </b-row>
                      <b-row class="p-2">
                        <b-col lg="4" sm="12">
                          <ValidationProvider name="Total electricity cost" vid="total_electricity_cost">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="total_electricity_cost"
                              slot-scope="{ valid, errors }"
                              style="font-size: 13px"
                            >
                            <template v-slot:label>
                              {{ $t('irriPumpInformation.tot_biddut_cost') }}
                            </template>
                            <b-form-input
                              id="total_electricity_cost"
                              v-model="pumpReportEntry.total_electricity_cost"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="4" sm="12">
                          <ValidationProvider name="Operator salary" vid="operator_salary">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="operator_salary"
                              slot-scope="{ valid, errors }"
                              style="font-size: 13px"
                            >
                            <template v-slot:label>
                              {{ $t('irriPumpInformation.operator_salary') }}
                            </template>
                            <b-form-input
                              id="operator_salary"
                              v-model="pumpReportEntry.operator_salary"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="4" sm="12">
                          <ValidationProvider name="Maintenance cost" vid="maintance_cost">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="maintance_cost"
                              slot-scope="{ valid, errors }"
                              style="font-size: 13px"
                            >
                            <template v-slot:label>
                              {{ $t('irriPumpInformation.maintance_cost') }}
                            </template>
                            <b-form-input
                              id="maintance_cost"
                              v-model="pumpReportEntry.maintance_cost"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                      </b-row>
                      <b-row class="p-2">
                        <b-col lg="4" sm="12">
                          <ValidationProvider name="Other cost" vid="other_cost">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="other_cost"
                              slot-scope="{ valid, errors }"
                              style="font-size: 13px"
                            >
                            <template v-slot:label>
                              {{ $t('irriPumpInformation.other_cost') }}
                            </template>
                            <b-form-input
                              id="other_cost"
                              v-model="pumpReportEntry.other_cost"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="4" sm="12">
                          <ValidationProvider name="Total cost" vid="total_cost">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="total_cost"
                              slot-scope="{ valid, errors }"
                              style="font-size: 13px"
                            >
                            <template v-slot:label>
                              {{ $t('irriPumpInformation.tot_cost') }}
                            </template>
                            <b-form-input
                              id="total_cost"
                              v-model="pumpReportEntry.total_cost"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="4" sm="12">
                          <ValidationProvider name="Total income" vid="total_income">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="total_income"
                              slot-scope="{ valid, errors }"
                              style="font-size: 13px"
                            >
                            <template v-slot:label>
                              {{ $t('irriPumpInformation.tot_income') }}
                            </template>
                            <b-form-input
                              type="number"
                              min="0"
                              id="total_income"
                              v-model="pumpReportEntry.total_income"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                      </b-row>
                    </div>
                    <b-row>
                      <b-col lg="3" sm="12">
                        <ValidationProvider name="llp expense" vid="llp_expense">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="llp_expense"
                            slot-scope="{ valid, errors }"
                            style="font-size: 13px"
                          >
                          <template v-slot:label>
                            {{ $t('irriPumpInformation.llp_expense') }}
                          </template>
                          <b-form-input
                            type="number"
                            min="0"
                            id="llp_expense"
                            v-model="pumpReportEntry.llp_expense"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="3" sm="12">
                        <ValidationProvider name="pontoon expense" vid="pontoon_expense">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="pontoon_expense"
                            slot-scope="{ valid, errors }"
                            style="font-size: 13px"
                          >
                          <template v-slot:label>
                            {{ $t('irriPumpInformation.pontoon_expense') }}
                          </template>
                          <b-form-input
                            type="number"
                            min="0"
                            id="pontoon_expense"
                            v-model="pumpReportEntry.pontoon_expense"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="3" sm="12">
                        <ValidationProvider name="kharif 1 llp" vid="kharif_1_llp">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="kharif_1_llp"
                            slot-scope="{ valid, errors }"
                            style="font-size: 13px"
                          >
                          <template v-slot:label>
                            {{ $t('irriPumpInformation.kharif_1_llp') }}
                          </template>
                          <b-form-input
                            type="number"
                            min="0"
                            id="kharif_1_llp"
                            v-model="pumpReportEntry.kharif_1_llp"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="3" sm="12">
                        <ValidationProvider name="kharif 2 llp" vid="kharif_2_llp">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="kharif_2_llp"
                            slot-scope="{ valid, errors }"
                            style="font-size: 13px"
                          >
                          <template v-slot:label>
                            {{ $t('irriPumpInformation.kharif_2_llp') }}
                          </template>
                          <b-form-input
                            type="number"
                            min="0"
                            id="kharif_2_llp"
                            v-model="pumpReportEntry.kharif_2_llp"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="3" sm="12">
                        <ValidationProvider name="robi llp" vid="robi_llp">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="robi_llp"
                            slot-scope="{ valid, errors }"
                            style="font-size: 13px"
                          >
                          <template v-slot:label>
                            {{ $t('irriPumpInformation.robi_llp') }}
                          </template>
                          <b-form-input
                            type="number"
                            min="0"
                            id="robi_llp"
                            v-model="pumpReportEntry.robi_llp"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="3" sm="12">
                        <ValidationProvider name="kharif 1 total gonku" vid="kharif_1_total_gonku">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="kharif_1_total_gonku"
                            slot-scope="{ valid, errors }"
                            style="font-size: 13px"
                          >
                          <template v-slot:label>
                            {{ $t('irriPumpInformation.kharif_1_total_gonku') }}
                          </template>
                          <b-form-input
                            id="kharif_1_total_gonku"
                            v-model="pumpReportEntry.kharif_1_total_gonku"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="3" sm="12">
                        <ValidationProvider name="kharif 2 total gonku" vid="kharif_2_total_gonku">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="kharif_2_total_gonku"
                            slot-scope="{ valid, errors }"
                            style="font-size: 13px"
                          >
                          <template v-slot:label>
                            {{ $t('irriPumpInformation.kharif_2_total_gonku') }}
                          </template>
                          <b-form-input
                            id="kharif_2_total_gonku"
                            v-model="pumpReportEntry.kharif_2_total_gonku"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="6" sm="12">
                        <ValidationProvider name="Ganku details" vid="ganku_details">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="ganku_details"
                            slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                            {{ $t('irriPumpInformation.ganku_details') }}
                          </template>
                          <b-form-input
                            v-model="pumpReportEntry.ganku_details"
                            id="ganku_details"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="12">
                        <ValidationProvider name="Supervising mechanic" vid="super_v_mechanic">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="super_v_mechanic"
                            slot-scope="{ valid, errors }"
                            style="font-size: 13px"
                          >
                          <template v-slot:label>
                            {{ $t('irriPumpInformation.super_v_mechanic') }}
                          </template>
                          <b-form-input
                            id="super_v_mechanic"
                            v-model="pumpReportEntry.super_v_mechanic"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="6" sm="12">
                        <ValidationProvider name="Supervising higher engineer" vid="super_v_higher_engr">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="super_v_higher_engr"
                            slot-scope="{ valid, errors }"
                            style="font-size: 13px"
                          >
                          <template v-slot:label>
                            {{ $t('irriPumpInformation.super_v_higher_engr') }}
                          </template>
                          <b-form-input
                            id="super_v_higher_engr"
                            v-model="pumpReportEntry.super_v_higher_engr"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="12">
                          <ValidationProvider name="Comments" vid="comments">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="comments"
                            slot-scope="{ valid, errors }"
                            style="font-size: 13px"
                          >
                          <template v-slot:label>
                            {{ $t('irriPumpInformation.comments') }}
                          </template>
                          <b-form-input
                            id="comments"
                            v-model="pumpReportEntry.comments"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                    </b-row>
                    <b-row class="mt-3">
                        <b-col></b-col>
                        <b-col>
                          <button  class="btn btn-primary btn-block" type="submit">{{ saveBtnName }}</button>
                        </b-col>
                        <b-col></b-col>
                    </b-row>
                  </b-form>
                </ValidationObserver>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { irriSchemeServiceBaseUrl } from '@/config/api_config'
import { deepTubeWellReportStore, getdeepTubwellEditingData, deepTubeWellReportUpdate } from '../../api/routes'
import { mapGetters } from 'vuex'
import flatpickr from 'flatpickr'

export default {
  components: {
    ValidationObserver,
    ValidationProvider
  },
  async created () {
    this.authUserData = this.authUser
    const id = this.$route.query.id
    if (id) {
      this.getData()
    }
  },
  mounted () {
    core.index()
    flatpickr('#datepicker', {})
    if (this.authUser.role_id === 0 && this.authUser.is_org_admin === 1) {
      this.pumpReportEntry = Object.assign({}, this.pumpReportEntry, {
        org_id: this.authUser.org_id
      })
    } else if (this.authUser.role_id === 0) {
      this.pumpReportEntry = Object.assign({}, this.pumpReportEntry, {
        org_id: this.authUser.office_detail.org_id,
        division_id: this.authUser.office_detail.division_id,
        district_id: this.authUser.office_detail.district_id,
        upazilla_id: this.authUser.office_detail.upazilla_id
      })
    }
  },
  data () {
    return {
        schemeTypeListData: [],
        authUserData: {},
        loading: false,
        pumpReportEntry: {
            org_id: '0',
            pump_id: '0',
            division_id: '0',
            district_id: '0',
            upazilla_id: '0',
            union_id: '0',
            mouza_no: '',
            jl_no: '',
            plot_no: '',
            report_type_id: 0,
            install_date_of_excavation: '',
            install_date_of_commission: '',
            install_accepted_participation: '',
            rehab_date_of_excavation: '',
            rehab_date_of_commission: '',
            rehab_accepted_participation: '',
            project_name: '',
            operator_name: '',
            deep_tube_well_nature: '',
            ms: '',
            fg: '',
            updc: '',
            filter_ms: '',
            filter_fg: '',
            filter_updc: '',
            dia: '',
            amount: '',
            vertical_make_o_model: '',
            vertical_power: '',
            vertical_unit_consumption: '',
            turbine_make_o_model: '',
            turbine_power: '',
            turbine_unit_consumption: '',
            head: '',
            discharge: '',
            command_area: '',
            kharif_1_aus: 0,
            kharif_1_others: 0,
            kharif_1_total: 0,
            kharif_1_aus_per_hector_cost: '',
            kharif_2_aman: 0,
            kharif_2_others: 0,
            kharif_2_total: 0,
            kharif_2_aman_per_hector_cost: '',
            borou: 0,
            borou_per_hector_cost: '',
            wheat: 0,
            wheat_per_hector_cost: '',
            potato: 0,
            potato_per_hector_cost: '',
            corn: 0,
            corn_per_hector_cost: '',
            mustard: 0,
            mustard_per_hector_cost: '',
            lentils: 0,
            lentils_per_hector_cost: '',
            vegetables: 0,
            vegetables_per_hector_cost: '',
            robi_total: 0,
            actual: '',
            barga: '',
            other_scheme_area: '',
            beneficial_farmer_total: '',
            start_reading: '',
            end_reading: '',
            total_uses_unit: '',
            hourly_used_unit: '',
            total_active_hour: '',
            hourly_irri_charge: '',
            recoverable_irri_payment: '',
            collected_irri_payment: '',
            unpaid_money: '',
            total_electricity_cost: '',
            operator_salary: '',
            maintance_cost: '',
            other_cost: '',
            total_cost: '',
            total_income: '',
            llp_expense: '',
            pontoon_expense: '',
            kharif_1_llp: '',
            kharif_2_llp: '',
            robi_llp: '',
            kharif_1_total_gonku: '',
            kharif_2_total_gonku: '',
            ganku_details: '',
            super_v_mechanic: '',
            super_v_higher_engr: '',
            comments: ''
        },
        pumpInformationList: [],
        pumpInfo: {},
        pumpDropdownList: [],
        districtList: [],
        upazilaList: [],
        unionList: [],
        schManDistrictList: [],
        schManUpazilaList: [],
        schManUnionList: [],
        pumpDistrictList: [],
        pumpUpazilaList: [],
        pumpUnionList: [],
        farmer_id: '',
        reportTypes: [
          {
            value: 1,
            text: 'LLP',
            text_bn: 'এলএলপি'
          },
          {
            value: 2,
            text: 'Deep Tubewell',
            text_bn: 'গভীর নলকূপ'
          }
        ]
    }
  },
  computed: {
    ...mapGetters({
      authUser: 'Auth/authUser'
    }),
    saveBtnName () {
      return this.farmer_id ? this.$t('globalTrans.update') : this.$t('globalTrans.save')
    },
    organizationList: function () {
      return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    divisionList: function () {
      return this.$store.state.commonObj.divisionList
    },
    reportTypeList: function () {
      const reportType = this.reportTypes
      return reportType.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { value: item.value, text: item.text_bn }
        } else {
          return { value: item.value, text: item.text }
        }
      })
    }
  },
  watch: {
    'pumpReportEntry.kharif_1_aus': function (newVal, oldVal) {
       this.getTotalKharif1()
    },
    'pumpReportEntry.kharif_1_others': function (newVal, oldVal) {
       this.getTotalKharif1()
    },
    'pumpReportEntry.kharif_2_aman': function (newVal, oldVal) {
       this.getTotalKharif2()
    },
    'pumpReportEntry.borou': function (newVal, oldVal) {
       this.getTotalRobi()
    },
    'pumpReportEntry.wheat': function (newVal, oldVal) {
       this.getTotalRobi()
    },
    'pumpReportEntry.potato': function (newVal, oldVal) {
       this.getTotalRobi()
    },
    'pumpReportEntry.corn': function (newVal, oldVal) {
       this.getTotalRobi()
    },
    'pumpReportEntry.mustard': function (newVal, oldVal) {
       this.getTotalRobi()
    },
    'pumpReportEntry.lentils': function (newVal, oldVal) {
       this.getTotalRobi()
    },
    'pumpReportEntry.vegetables': function (newVal, oldVal) {
       this.getTotalRobi()
    },
    'pumpReportEntry.pump_id': function (newVal, oldVal) {
       this.getpumpSingleInfo(newVal)
    },
    'pumpReportEntry.division_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.districtList = this.getDistrictList(newVal)
      }
    },
    'pumpReportEntry.district_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.upazilaList = this.getUpazilaList(newVal)
      }
    },
    'pumpReportEntry.upazilla_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.unionList = this.getUnionList(newVal)
        this.pumpDropdownList = this.getPumplist(newVal)
      }
    },
    'pumpReportEntry.union_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.pumpDropdownList = this.getPumplist(newVal)
      }
    }
  },
  methods: {
    getTotalKharif1 () {
      if (this.pumpReportEntry.kharif_1_aus === '') {
        this.pumpReportEntry.kharif_1_aus = 0
      }
      if (this.pumpReportEntry.kharif_1_others === '') {
        this.pumpReportEntry.kharif_1_others = 0
      }
      this.pumpReportEntry.kharif_1_total = (parseFloat(this.pumpReportEntry.kharif_1_aus) + parseFloat(this.pumpReportEntry.kharif_1_others))
    },
    getTotalKharif2 () {
      if (this.pumpReportEntry.kharif_2_aman === '') {
        this.pumpReportEntry.kharif_2_aman = 0
      }
      if (this.pumpReportEntry.kharif_2_others === '') {
        this.pumpReportEntry.kharif_2_others = 0
      }
      this.pumpReportEntry.kharif_2_total = (parseFloat(this.pumpReportEntry.kharif_2_aman) + parseFloat(this.pumpReportEntry.kharif_2_others))
    },
    getTotalRobi () {
      if (this.pumpReportEntry.borou === '') {
        this.pumpReportEntry.borou = 0
      }
      if (this.pumpReportEntry.wheat === '') {
        this.pumpReportEntry.wheat = 0
      }
      if (this.pumpReportEntry.potato === '') {
        this.pumpReportEntry.potato = 0
      }
      if (this.pumpReportEntry.corn === '') {
        this.pumpReportEntry.corn = 0
      }
      if (this.pumpReportEntry.mustard === '') {
        this.pumpReportEntry.mustard = 0
      }
      if (this.pumpReportEntry.lentils === '') {
        this.pumpReportEntry.lentils = 0
      }
      if (this.pumpReportEntry.vegetables === '') {
        this.pumpReportEntry.vegetables = 0
      }
      if (this.pumpReportEntry.vegetables === '') {
        this.pumpReportEntry.vegetables = 0
      }
      this.pumpReportEntry.robi_total = (
                            parseFloat(this.pumpReportEntry.borou) +
                            parseFloat(this.pumpReportEntry.wheat) +
                            parseFloat(this.pumpReportEntry.potato) +
                            parseFloat(this.pumpReportEntry.corn) +
                            parseFloat(this.pumpReportEntry.mustard) +
                            parseFloat(this.pumpReportEntry.lentils) +
                            parseFloat(this.pumpReportEntry.vegetables)
                          )
    },
    getData () {
     RestApi.getData(irriSchemeServiceBaseUrl, getdeepTubwellEditingData + this.$route.query.id).then(response => {
        this.pumpReportEntry = response.data
      })
    },
    getPumplist (upazillaOrUnionId) {
      this.pumpInformationList = this.$store.state.IrriConfig.commonObj.pumpInfoList.filter(pumpInfo => (pumpInfo.upazilla_id === upazillaOrUnionId || pumpInfo.union_id === upazillaOrUnionId) && pumpInfo.status === 0)
      return this.pumpInformationList.map((obj, index) => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.id, text: obj.pump_id }
        } else {
          return { value: obj.id, text: obj.pump_id }
        }
      })
    },
    async register () {
      this.loading = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadingState = { loading: false, listReload: false }

      if (this.$route.query.id) {
        result = await RestApi.putData(irriSchemeServiceBaseUrl, `${deepTubeWellReportUpdate}/${this.$route.query.id}`, this.pumpReportEntry)
      } else {
        result = await RestApi.postData(irriSchemeServiceBaseUrl, deepTubeWellReportStore, this.pumpReportEntry)
      }

      loadingState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadingState)
      this.loading = false
      if (result.success) {
          this.$store.dispatch('mutateCommonProperties', { hasDropdownLoaded: false })
          this.$toast.success({
            title: this.$t('globalTrans.success'),
            message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
            color: '#D6E09B'
          })
          setTimeout(async () => {
            this.$router.push('/irrigation/pump-information/pump-report-entry')
          }, 500)
      } else {
        this.$refs.form.setErrors(result.errors)
      }
    },
    getDistrictList (divisionId = null) {
      const districtList = this.$store.state.commonObj.districtList
      if (divisionId) {
        return districtList.filter(district => district.division_id === divisionId)
      }
      return districtList
    },
    getUpazilaList (districtId = null) {
      const upazilaList = this.$store.state.commonObj.upazilaList

      if (districtId) {
        return upazilaList.filter(upazila => upazila.district_id === districtId)
      }
      return upazilaList
    },
    getUnionList (upazilaId = null) {
      const unionList = this.$store.state.commonObj.unionList
      if (upazilaId) {
        return unionList.filter(union => union.upazilla_id === upazilaId)
      }
      return unionList
    },
    getpumpSingleInfo (pumpId) {
      this.pumpInfo = this.pumpInformationList.find(pumpInfo => pumpInfo.id === pumpId)
      this.pumpReportEntry.division_id = this.pumpInfo.division_id
      this.pumpReportEntry.district_id = this.pumpInfo.district_id
      this.pumpReportEntry.upazilla_id = this.pumpInfo.upazilla_id
      this.pumpReportEntry.union_id = this.pumpInfo.union_id
      this.pumpReportEntry.mouza_no = this.pumpInfo.mouza_no
      this.pumpReportEntry.jl_no = this.pumpInfo.jl_no
      this.pumpReportEntry.plot_no = this.pumpInfo.plot_no
    }
  }
}
</script>
