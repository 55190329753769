import i18n from '@/i18n'
import Store from '@/store'
// import ReportHeading from '@/Utils/report-head'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'
import { dateFormat } from '@/Utils/fliter'

const exportPdfDetails = async (reportTitle, data, thisObject) => {
  try {
    Store.commit('mutateCommonProperties', {
      loading: true
    })
    if (i18n.locale === 'bn') {
      pdfMake.vfs = pdfFontsBn.pdfMake.vfs
    } else {
      pdfMake.vfs = pdfFontsEn.pdfMake.vfs
    }
    // const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
    const pdfContent = [
      { text: thisObject.$t('irriPumpInformation.pump_report_details'), style: 'header2', alignment: 'center' }
    ]

    pdfContent.push([
      { text: reportTitle, style: 'header3', alignment: 'center', decoration: 'underline' }
    ])

    const allRowsHead = [
      [
        { text: thisObject.$t('org_pro.organization'), alignment: 'right', style: 'search' },
        { text: ':', style: 'search', alignment: 'center' },
        { text: i18n.locale === 'en' ? data.org_name : data.org_name_bn, alignment: 'left', style: 'search' },
        { text: thisObject.$t('irriPumpInformation.pump_id'), alignment: 'right', style: 'search' },
        { text: ':', style: 'search', alignment: 'center' },
        { text: i18n.locale === 'en' ? data.pump_id : thisObject.$n(data.pump_id), alignment: 'left', style: 'search' }
      ]
    ]

    allRowsHead.push([
      { text: thisObject.$t('complain.division'), alignment: 'right', style: 'search' },
      { text: ':', style: 'search', alignment: 'center' },
      { text: i18n.locale === 'en' ? data.division_name : data.division_name_bn, alignment: 'left', style: 'search' },
      { text: thisObject.$t('complain.district'), alignment: 'right', style: 'search' },
      { text: ':', style: 'search', alignment: 'center' },
      { text: i18n.locale === 'en' ? data.district_name : data.district_name_bn, alignment: 'left', style: 'search' }
    ])

    allRowsHead.push([
      { text: thisObject.$t('complain.upazila'), alignment: 'right', style: 'search' },
      { text: ':', style: 'search', alignment: 'center' },
      { text: i18n.locale === 'en' ? data.upazilla_name : data.upazilla_name_bn, alignment: 'left', style: 'search' },
      { text: thisObject.$t('complain.union'), alignment: 'right', style: 'search' },
      { text: ':', style: 'search', alignment: 'center' },
      { text: i18n.locale === 'en' ? data.union_name : data.union_name_bn, alignment: 'left', style: 'search' }
      // { text: thisObject.$n('0') + thisObject.$n(data.far_mobile_no, { useGrouping: false }), alignment: 'left', style: 'search' }
    ])

    allRowsHead.push([
      { text: thisObject.$t('irriPumpInformation.mouza_no'), alignment: 'right', style: 'search' },
      { text: ':', style: 'search', alignment: 'center' },
      { text: i18n.locale === 'en' ? data.mouza_no : data.mouza_no_bn, alignment: 'left', style: 'search' },
      { text: thisObject.$t('irriPumpInformation.jl_no'), alignment: 'right', style: 'search' },
      { text: ':', style: 'search', alignment: 'center' },
      { text: i18n.locale === 'en' ? data.jl_no : thisObject.$n(data.jl_no), alignment: 'left', style: 'search' }
    ])

    allRowsHead.push([
      { text: thisObject.$t('irriPumpInformation.plot_no'), alignment: 'right', style: 'search' },
      { text: ':', style: 'search', alignment: 'center' },
      { text: i18n.locale === 'en' ? data.plot_no : thisObject.$n(data.plot_no), alignment: 'left', style: 'search' },
      { text: '', alignment: 'right', style: 'search' },
      { text: '', style: 'search', alignment: 'center' },
      { text: '', alignment: 'left', style: 'search' }
    ])

    pdfContent.push({
      table: {
        headerRows: 0,
        widths: ['24%', '2%', '24%', '24%', '2%', '24%'],
        body: allRowsHead
      },
      layout: {
        hLineWidth: function (i, node) {
          return 0
        },
        vLineWidth: function (i, node) {
          return 0
        }
      }
    })

    pdfContent.push([
      { text: thisObject.$t('irriPumpInformation.deep_tube_well_installation'), style: 'header3', alignment: 'center', decoration: 'underline' }
    ])

    const tubeWellInstall = [
      [
        { text: thisObject.$t('irriPumpInformation.date_of_excavation'), alignment: 'left', style: 'search' },
        { text: ':', style: 'search', alignment: 'center' },
        { text: dateFormat(data.date_of_excavation), alignment: 'left', style: 'search' },
        { text: thisObject.$t('irriPumpInformation.date_of_commission'), alignment: 'left', style: 'search' },
        { text: ':', style: 'search', alignment: 'center' },
        { text: dateFormat(data.date_of_commission), alignment: 'left', style: 'search' },
        { text: thisObject.$t('irriPumpInformation.accepted_participation'), alignment: 'left', style: 'search' },
        { text: ':', style: 'search', alignment: 'center' },
        { text: i18n.locale === 'en' ? data.install_accepted_participation : thisObject.$n(data.install_accepted_participation), alignment: 'left', style: 'search' }
      ]
    ]

    pdfContent.push({
      table: {
        headerRows: 0,
        widths: ['20%', '2%', '15%', '20%', '2%', '15%', '25%', '2%', '15%'],
        body: tubeWellInstall
      },
      layout: {
        hLineWidth: function (i, node) {
          return 0
        },
        vLineWidth: function (i, node) {
          return 0
        }
      }
    })

    pdfContent.push([
      { text: thisObject.$t('irriPumpInformation.deep_tube_well_rehabilitation'), style: 'header3', alignment: 'center', decoration: 'underline' }
    ])

    const tubeWellRehab = [
      [
        { text: thisObject.$t('irriPumpInformation.date_of_excavation'), alignment: 'left', style: 'search' },
        { text: ':', style: 'search', alignment: 'center' },
        { text: dateFormat(data.date_of_excavation), alignment: 'left', style: 'search' },
        { text: thisObject.$t('irriPumpInformation.date_of_commission'), alignment: 'right', style: 'search' },
        { text: ':', style: 'search', alignment: 'center' },
        { text: dateFormat(data.date_of_commission), alignment: 'left', style: 'search' },
        { text: thisObject.$t('irriPumpInformation.accepted_participation'), alignment: 'right', style: 'search' },
        { text: ':', style: 'search', alignment: 'center' },
        { text: i18n.locale === 'en' ? data.install_accepted_participation : thisObject.$n(data.install_accepted_participation), alignment: 'left', style: 'search' }
      ],
      [
        { text: thisObject.$t('irriPumpInformation.project_name'), alignment: 'left', style: 'search' },
        { text: ':', style: 'search', alignment: 'center' },
        { text: i18n.locale === 'en' ? data.project_name : data.project_name_bn, alignment: 'left', style: 'search' },
        { text: thisObject.$t('irriPumpInformation.deep_tube_well_nature'), alignment: 'right', style: 'search' },
        { text: ':', style: 'search', alignment: 'center' },
        { text: i18n.locale === 'en' ? data.deep_tube_well_nature : data.deep_tube_well_nature_bn, alignment: 'left', style: 'search' },
        { text: thisObject.$t('irriPumpInformation.operator_name'), alignment: 'right', style: 'search' },
        { text: ':', style: 'search', alignment: 'center' },
        { text: i18n.locale === 'en' ? data.operator_name : data.operator_name_bn, alignment: 'left', style: 'search' }
      ]
    ]

    pdfContent.push({
      table: {
        headerRows: 0,
        widths: ['15%', '2%', '12%', '15%', '2%', '15%', '20%', '2%', '15%'],
        body: tubeWellRehab
      },
      layout: {
        hLineWidth: function (i, node) {
          return 0
        },
        vLineWidth: function (i, node) {
          return 0
        }
      }
    })

    pdfContent.push([
      { text: thisObject.$t('irriPumpInformation.information_on_goods'), style: 'header3', alignment: 'center', decoration: 'underline' }
    ])
    pdfContent.push([
      { text: thisObject.$t('irriPumpInformation.housing_pipe'), style: 'header4', alignment: 'left' }
    ])

    const goodsInfo = [
      [
        { text: thisObject.$t('irriPumpInformation.ms'), alignment: 'left', style: 'search' },
        { text: ':', style: 'search', alignment: 'center' },
        { text: i18n.locale === 'en' ? data.ms : thisObject.$n(data.ms), alignment: 'left', style: 'search' },
        { text: thisObject.$t('irriPumpInformation.fg'), alignment: 'right', style: 'search' },
        { text: ':', style: 'search', alignment: 'center' },
        { text: i18n.locale === 'en' ? data.fg : thisObject.$n(data.fg), alignment: 'left', style: 'search' },
        { text: thisObject.$t('irriPumpInformation.updc'), alignment: 'right', style: 'search' },
        { text: ':', style: 'search', alignment: 'center' },
        { text: i18n.locale === 'en' ? data.updc : thisObject.$n(data.updc), alignment: 'left', style: 'search' }
      ]
    ]

    pdfContent.push({
      table: {
        headerRows: 0,
        widths: ['15%', '2%', '12%', '15%', '2%', '15%', '20%', '2%', '15%'],
        body: goodsInfo
      },
      layout: {
        hLineWidth: function (i, node) {
          return 0
        },
        vLineWidth: function (i, node) {
          return 0
        }
      }
    })

    pdfContent.push([
      { text: thisObject.$t('irriPumpInformation.filter'), style: 'header4', alignment: 'left' }
    ])

    const filterInfo = [
      [
        { text: thisObject.$t('irriPumpInformation.filter_ms'), alignment: 'left', style: 'search' },
        { text: ':', style: 'search', alignment: 'center' },
        { text: i18n.locale === 'en' ? data.filter_ms : thisObject.$n(data.filter_ms), alignment: 'left', style: 'search' },
        { text: thisObject.$t('irriPumpInformation.filter_fg'), alignment: 'right', style: 'search' },
        { text: ':', style: 'search', alignment: 'center' },
        { text: i18n.locale === 'en' ? data.filter_fg : thisObject.$n(data.filter_fg), alignment: 'left', style: 'search' },
        { text: thisObject.$t('irriPumpInformation.filter_updc'), alignment: 'right', style: 'search' },
        { text: ':', style: 'search', alignment: 'center' },
        { text: i18n.locale === 'en' ? data.filter_updc : thisObject.$n(data.filter_updc), alignment: 'left', style: 'search' }
      ]
    ]

    pdfContent.push({
      table: {
        headerRows: 0,
        widths: ['25%', '2%', '12%', '10%', '2%', '10%', '20%', '2%', '15%'],
        body: filterInfo
      },
      layout: {
        hLineWidth: function (i, node) {
          return 0
        },
        vLineWidth: function (i, node) {
          return 0
        }
      }
    })

    pdfContent.push([
      { text: thisObject.$t('irriPumpInformation.turbine_submersible_motor'), style: 'header4', alignment: 'left' }
    ])

    const turbineMotor = [
      [
        { text: thisObject.$t('irriPumpInformation.dia'), alignment: 'left', style: 'search' },
        { text: ':', style: 'search', alignment: 'center' },
        { text: i18n.locale === 'en' ? data.dia : thisObject.$n(data.dia), alignment: 'left', style: 'search' },
        { text: thisObject.$t('irriPumpInformation.amount'), alignment: 'right', style: 'search' },
        { text: ':', style: 'search', alignment: 'center' },
        { text: i18n.locale === 'en' ? data.amount : thisObject.$n(data.amount), alignment: 'left', style: 'search' }
      ]
    ]

    pdfContent.push({
      table: {
        headerRows: 0,
        widths: ['18%', '2%', '12%', '20%', '2%', '20%'],
        body: turbineMotor
      },
      layout: {
        hLineWidth: function (i, node) {
          return 0
        },
        vLineWidth: function (i, node) {
          return 0
        }
      }
    })

    pdfContent.push([
      { text: thisObject.$t('irriPumpInformation.technical_details'), style: 'header3', alignment: 'center', decoration: 'underline' }
    ])
    pdfContent.push([
      { text: thisObject.$t('irriPumpInformation.vertical'), style: 'header4', alignment: 'left' }
    ])

    const techDetails = [
      [
        { text: thisObject.$t('irriPumpInformation.make_o_model'), alignment: 'left', style: 'search' },
        { text: ':', style: 'search', alignment: 'center' },
        { text: i18n.locale === 'en' ? data.vertical_make_o_model : data.vertical_make_o_model, alignment: 'left', style: 'search' },
        { text: thisObject.$t('irriPumpInformation.unit_consumption'), alignment: 'right', style: 'search' },
        { text: ':', style: 'search', alignment: 'center' },
        { text: i18n.locale === 'en' ? data.vertical_unit_consumption : thisObject.$n(data.vertical_unit_consumption), alignment: 'left', style: 'search' },
        { text: thisObject.$t('irriPumpInformation.power'), alignment: 'right', style: 'search' },
        { text: ':', style: 'search', alignment: 'center' },
        { text: i18n.locale === 'en' ? data.vertical_power : thisObject.$n(data.vertical_power), alignment: 'left', style: 'search' }
      ]
    ]

    pdfContent.push({
      table: {
        headerRows: 0,
        widths: ['20%', '2%', '12%', '20%', '2%', '5%', '30%', '2%', '10%'],
        body: techDetails
      },
      layout: {
        hLineWidth: function (i, node) {
          return 0
        },
        vLineWidth: function (i, node) {
          return 0
        }
      }
    })

    pdfContent.push([
      { text: thisObject.$t('irriPumpInformation.turbine_submersible_motor'), style: 'header4', alignment: 'left' }
    ])

    const techTurbinMotor = [
      [
        { text: thisObject.$t('irriPumpInformation.make_o_model'), alignment: 'left', style: 'search' },
        { text: ':', style: 'search', alignment: 'center' },
        { text: i18n.locale === 'en' ? data.turbine_make_o_model : data.turbine_make_o_model, alignment: 'left', style: 'search' },
        { text: thisObject.$t('irriPumpInformation.unit_consumption'), alignment: 'right', style: 'search' },
        { text: ':', style: 'search', alignment: 'center' },
        { text: i18n.locale === 'en' ? data.turbine_unit_consumption : thisObject.$n(data.turbine_unit_consumption), alignment: 'left', style: 'search' },
        { text: thisObject.$t('irriPumpInformation.power'), alignment: 'right', style: 'search' },
        { text: ':', style: 'search', alignment: 'center' },
        { text: i18n.locale === 'en' ? data.turbine_power : thisObject.$n(data.turbine_power), alignment: 'left', style: 'search' }
      ],
      [
        { text: thisObject.$t('irriPumpInformation.head'), alignment: 'left', style: 'search' },
        { text: ':', style: 'search', alignment: 'center' },
        { text: i18n.locale === 'en' ? data.head : thisObject.$n(data.head), alignment: 'left', style: 'search' },
        { text: thisObject.$t('irriPumpInformation.discharge'), alignment: 'right', style: 'search' },
        { text: ':', style: 'search', alignment: 'center' },
        { text: i18n.locale === 'en' ? data.discharge : thisObject.$n(data.discharge), alignment: 'left', style: 'search' },
        { text: thisObject.$t('irriPumpInformation.command_area'), alignment: 'right', style: 'search' },
        { text: ':', style: 'search', alignment: 'center' },
        { text: i18n.locale === 'en' ? data.command_area : thisObject.$n(data.command_area), alignment: 'left', style: 'search' }
      ]
    ]

    pdfContent.push({
      table: {
        headerRows: 0,
        widths: ['20%', '2%', '12%', '20%', '2%', '5%', '30%', '2%', '10%'],
        body: techTurbinMotor
      },
      layout: {
        hLineWidth: function (i, node) {
          return 0
        },
        vLineWidth: function (i, node) {
          return 0
        }
      }
    })

    pdfContent.push([
      { text: '', style: 'header3', alignment: 'center' }
    ])
    pdfContent.push([
      { text: '', style: 'header3', alignment: 'center' }
    ])
    pdfContent.push([
      { text: '', style: 'header3', alignment: 'center' }
    ])
    pdfContent.push([
      { text: thisObject.$t('irriPumpInformation.amount_irrigated_land'), style: 'header3', alignment: 'center', decoration: 'underline' }
    ])
    pdfContent.push([
      { text: thisObject.$t('irriPumpInformation.kharif_1'), style: 'header4', alignment: 'left' }
    ])

    const kharif1 = [
      [
        { text: thisObject.$t('irriPumpInformation.aus'), alignment: 'left', style: 'search' },
        { text: ':', style: 'search', alignment: 'center' },
        { text: i18n.locale === 'en' ? data.kharif_1_aus : thisObject.$n(data.kharif_1_aus), alignment: 'left', style: 'search' },
        { text: thisObject.$t('irriPumpInformation.others'), alignment: 'right', style: 'search' },
        { text: ':', style: 'search', alignment: 'center' },
        { text: i18n.locale === 'en' ? data.kharif_1_others : thisObject.$n(data.kharif_1_others), alignment: 'left', style: 'search' },
        { text: thisObject.$t('irriPumpInformation.total'), alignment: 'right', style: 'search' },
        { text: ':', style: 'search', alignment: 'center' },
        { text: i18n.locale === 'en' ? data.kharif_1_total : thisObject.$n(data.kharif_1_total), alignment: 'left', style: 'search' }
      ]
    ]

    pdfContent.push({
      table: {
        headerRows: 0,
        widths: ['15%', '2%', '12%', '15%', '2%', '15%', '20%', '2%', '15%'],
        body: kharif1
      },
      layout: {
        hLineWidth: function (i, node) {
          return 0
        },
        vLineWidth: function (i, node) {
          return 0
        }
      }
    })

    pdfContent.push([
      { text: thisObject.$t('irriPumpInformation.kharif_2'), style: 'header4', alignment: 'left' }
    ])

    const kharaif2 = [
      [
        { text: thisObject.$t('irriPumpInformation.aman'), alignment: 'left', style: 'search' },
        { text: ':', style: 'search', alignment: 'center' },
        { text: i18n.locale === 'en' ? data.kharif_2_aman : thisObject.$n(data.kharif_2_aman), alignment: 'left', style: 'search' },
        { text: thisObject.$t('irriPumpInformation.others'), alignment: 'right', style: 'search' },
        { text: ':', style: 'search', alignment: 'center' },
        { text: i18n.locale === 'en' ? data.kharif_2_others : thisObject.$n(data.kharif_2_others), alignment: 'left', style: 'search' },
        { text: thisObject.$t('irriPumpInformation.total'), alignment: 'right', style: 'search' },
        { text: ':', style: 'search', alignment: 'center' },
        { text: i18n.locale === 'en' ? data.kharif_2_total : thisObject.$n(data.kharif_2_total), alignment: 'left', style: 'search' }
      ]
    ]

    pdfContent.push({
      table: {
        headerRows: 0,
        widths: ['15%', '2%', '12%', '15%', '2%', '15%', '20%', '2%', '15%'],
        body: kharaif2
      },
      layout: {
        hLineWidth: function (i, node) {
          return 0
        },
        vLineWidth: function (i, node) {
          return 0
        }
      }
    })

    pdfContent.push([
      { text: thisObject.$t('irriPumpInformation.robi'), style: 'header4', alignment: 'left' }
    ])

    const robi = [
      [
        { text: thisObject.$t('irriPumpInformation.borou'), alignment: 'left', style: 'search' },
        { text: ':', style: 'search', alignment: 'center' },
        { text: i18n.locale === 'en' ? data.borou : thisObject.$n(data.borou), alignment: 'left', style: 'search' },
        { text: thisObject.$t('irriPumpInformation.mustard'), alignment: 'left', style: 'search' },
        { text: ':', style: 'search', alignment: 'center' },
        { text: i18n.locale === 'en' ? data.mustard : thisObject.$n(data.mustard), alignment: 'left', style: 'search' },
        { text: thisObject.$t('irriPumpInformation.wheat'), alignment: 'left', style: 'search' },
        { text: ':', style: 'search', alignment: 'center' },
        { text: i18n.locale === 'en' ? data.wheat : thisObject.$n(data.wheat), alignment: 'left', style: 'search' }
      ],
      [
        { text: thisObject.$t('irriPumpInformation.lentils'), alignment: 'left', style: 'search' },
        { text: ':', style: 'search', alignment: 'center' },
        { text: i18n.locale === 'en' ? data.lentils : thisObject.$n(data.lentils), alignment: 'left', style: 'search' },
        { text: thisObject.$t('irriPumpInformation.potato'), alignment: 'left', style: 'search' },
        { text: ':', style: 'search', alignment: 'center' },
        { text: i18n.locale === 'en' ? data.potato : thisObject.$n(data.potato), alignment: 'left', style: 'search' },
        { text: thisObject.$t('irriPumpInformation.vegetables'), alignment: 'left', style: 'search' },
        { text: ':', style: 'search', alignment: 'center' },
        { text: i18n.locale === 'en' ? data.vegetables : thisObject.$n(data.vegetables), alignment: 'left', style: 'search' }
      ],
      [
        { text: thisObject.$t('irriPumpInformation.corn'), alignment: 'left', style: 'search' },
        { text: ':', style: 'search', alignment: 'center' },
        { text: i18n.locale === 'en' ? data.corn : thisObject.$n(data.corn), alignment: 'left', style: 'search' },
        { text: thisObject.$t('irriPumpInformation.total'), alignment: 'left', style: 'search' },
        { text: ':', style: 'search', alignment: 'center' },
        { text: i18n.locale === 'en' ? data.robi_total : thisObject.$n(data.robi_total), alignment: 'left', style: 'search' },
        { text: '', alignment: 'right', style: 'search' },
        { text: '', style: 'search', alignment: 'center' },
        { text: '', alignment: 'left', style: 'search' }
      ]
    ]

    pdfContent.push({
      table: {
        headerRows: 0,
        widths: ['15%', '2%', '12%', '15%', '2%', '15%', '20%', '2%', '15%'],
        body: robi
      },
      layout: {
        hLineWidth: function (i, node) {
          return 0
        },
        vLineWidth: function (i, node) {
          return 0
        }
      }
    })

    pdfContent.push([
      { text: thisObject.$t('irriPumpInformation.beneficial_farmer'), style: 'header3', alignment: 'center', decoration: 'underline' }
    ])

    const beneficialFarmer = [
      [
        { text: thisObject.$t('irriPumpInformation.actual'), alignment: 'left', style: 'search' },
        { text: ':', style: 'search', alignment: 'center' },
        { text: i18n.locale === 'en' ? data.actual : thisObject.$n(data.actual), alignment: 'left', style: 'search' },
        { text: thisObject.$t('irriPumpInformation.barga'), alignment: 'left', style: 'search' },
        { text: ':', style: 'search', alignment: 'center' },
        { text: i18n.locale === 'en' ? data.barga : thisObject.$n(data.barga), alignment: 'left', style: 'search' },
        { text: thisObject.$t('irriPumpInformation.total'), alignment: 'left', style: 'search' },
        { text: ':', style: 'search', alignment: 'center' },
        { text: i18n.locale === 'en' ? data.beneficial_farmer_total : thisObject.$n(data.beneficial_farmer_total), alignment: 'left', style: 'search' }
      ]
    ]

    pdfContent.push({
      table: {
        headerRows: 0,
        widths: ['15%', '2%', '12%', '15%', '2%', '15%', '20%', '2%', '15%'],
        body: beneficialFarmer
      },
      layout: {
        hLineWidth: function (i, node) {
          return 0
        },
        vLineWidth: function (i, node) {
          return 0
        }
      }
    })

    pdfContent.push([
      { text: thisObject.$t('irriPumpInformation.tot_uses_unit'), style: 'header3', alignment: 'center', decoration: 'underline' }
    ])

    const totUseUnit = [
      [
        { text: thisObject.$t('irriPumpInformation.start_reading'), alignment: 'left', style: 'search' },
        { text: ':', style: 'search', alignment: 'center' },
        { text: i18n.locale === 'en' ? data.start_reading : thisObject.$n(data.start_reading), alignment: 'left', style: 'search' },
        { text: thisObject.$t('irriPumpInformation.end_reading'), alignment: 'left', style: 'search' },
        { text: ':', style: 'search', alignment: 'center' },
        { text: i18n.locale === 'en' ? data.end_reading : thisObject.$n(data.end_reading), alignment: 'left', style: 'search' },
        { text: thisObject.$t('irriPumpInformation.total'), alignment: 'left', style: 'search' },
        { text: ':', style: 'search', alignment: 'center' },
        { text: i18n.locale === 'en' ? data.total_uses_unit : thisObject.$n(data.total_uses_unit), alignment: 'left', style: 'search' }
      ]
    ]

    pdfContent.push({
      table: {
        headerRows: 0,
        widths: ['15%', '2%', '12%', '15%', '2%', '15%', '20%', '2%', '15%'],
        body: totUseUnit
      },
      layout: {
        hLineWidth: function (i, node) {
          return 0
        },
        vLineWidth: function (i, node) {
          return 0
        }
      }
    })

    pdfContent.push([
      { text: thisObject.$t('irriPumpInformation.irri_adai_descrip'), style: 'header3', alignment: 'center', decoration: 'underline' }
    ])

    const irriAdaiDesc = [
      [
        { text: thisObject.$t('irriPumpInformation.hourly_used_unit'), alignment: 'left', style: 'search' },
        { text: ':', style: 'search', alignment: 'center' },
        { text: i18n.locale === 'en' ? data.hourly_used_unit : thisObject.$n(data.hourly_used_unit), alignment: 'left', style: 'search' },
        { text: thisObject.$t('irriPumpInformation.tot_active_hour'), alignment: 'left', style: 'search' },
        { text: ':', style: 'search', alignment: 'center' },
        { text: i18n.locale === 'en' ? data.total_active_hour : thisObject.$n(data.total_active_hour), alignment: 'left', style: 'search' },
        { text: thisObject.$t('irriPumpInformation.hourly_irri_charge'), alignment: 'left', style: 'search' },
        { text: ':', style: 'search', alignment: 'center' },
        { text: i18n.locale === 'en' ? data.hourly_irri_charge : thisObject.$n(data.hourly_irri_charge), alignment: 'left', style: 'search' }
      ],
      [
        { text: thisObject.$t('irriPumpInformation.recoverable_irri_payment'), alignment: 'left', style: 'search' },
        { text: ':', style: 'search', alignment: 'center' },
        { text: i18n.locale === 'en' ? data.recoverable_irri_payment : thisObject.$n(data.recoverable_irri_payment), alignment: 'left', style: 'search' },
        { text: thisObject.$t('irriPumpInformation.collected_irri_payment'), alignment: 'left', style: 'search' },
        { text: ':', style: 'search', alignment: 'center' },
        { text: i18n.locale === 'en' ? data.collected_irri_payment : thisObject.$n(data.collected_irri_payment), alignment: 'left', style: 'search' },
        { text: thisObject.$t('irriPumpInformation.unpaid_money'), alignment: 'left', style: 'search' },
        { text: ':', style: 'search', alignment: 'center' },
        { text: i18n.locale === 'en' ? data.unpaid_money : thisObject.$n(data.unpaid_money), alignment: 'left', style: 'search' }
      ]
    ]

    pdfContent.push({
      table: {
        headerRows: 0,
        widths: ['25%', '2%', '8%', '25%', '2%', '5%', '25%', '2%', '6%'],
        body: irriAdaiDesc
      },
      layout: {
        hLineWidth: function (i, node) {
          return 0
        },
        vLineWidth: function (i, node) {
          return 0
        }
      }
    })

    pdfContent.push([
      { text: thisObject.$t('irriPumpInformation.mnge_ment_cost'), style: 'header3', alignment: 'center', decoration: 'underline' }
    ])

    const manegementCost = [
      [
        { text: thisObject.$t('irriPumpInformation.tot_biddut_cost'), alignment: 'left', style: 'search' },
        { text: ':', style: 'search', alignment: 'center' },
        { text: i18n.locale === 'en' ? data.total_electricity_cost : thisObject.$n(data.total_electricity_cost), alignment: 'left', style: 'search' },
        { text: thisObject.$t('irriPumpInformation.operator_salary'), alignment: 'left', style: 'search' },
        { text: ':', style: 'search', alignment: 'center' },
        { text: i18n.locale === 'en' ? data.operator_salary : thisObject.$n(data.operator_salary), alignment: 'left', style: 'search' },
        { text: thisObject.$t('irriPumpInformation.maintance_cost'), alignment: 'left', style: 'search' },
        { text: ':', style: 'search', alignment: 'center' },
        { text: i18n.locale === 'en' ? data.maintance_cost : thisObject.$n(data.maintance_cost), alignment: 'left', style: 'search' }
      ],
      [
        { text: thisObject.$t('irriPumpInformation.other_cost'), alignment: 'left', style: 'search' },
        { text: ':', style: 'search', alignment: 'center' },
        { text: i18n.locale === 'en' ? data.other_cost : thisObject.$n(data.other_cost), alignment: 'left', style: 'search' },
        { text: thisObject.$t('irriPumpInformation.tot_cost'), alignment: 'left', style: 'search' },
        { text: ':', style: 'search', alignment: 'center' },
        { text: i18n.locale === 'en' ? data.total_cost : thisObject.$n(data.total_cost), alignment: 'left', style: 'search' },
        { text: thisObject.$t('irriPumpInformation.tot_income'), alignment: 'left', style: 'search' },
        { text: ':', style: 'search', alignment: 'center' },
        { text: i18n.locale === 'en' ? data.total_income : thisObject.$n(data.total_income), alignment: 'left', style: 'search' }
      ]
    ]

    pdfContent.push({
      table: {
        headerRows: 0,
        widths: ['25%', '2%', '8%', '25%', '2%', '5%', '25%', '2%', '6%'],
        body: manegementCost
      },
      layout: {
        hLineWidth: function (i, node) {
          return 0
        },
        vLineWidth: function (i, node) {
          return 0
        }
      }
    })

    pdfContent.push([
      { text: '', style: 'header3', alignment: 'center' }
    ])

    const footerHead = [
      [
        { text: thisObject.$t('irriPumpInformation.ganku_details'), alignment: 'left', style: 'search' },
        { text: ':', style: 'search', alignment: 'center' },
        { text: i18n.locale === 'en' ? data.ganku_details : data.ganku_details_bn, alignment: 'left', style: 'search' }
      ],
      [
        { text: thisObject.$t('irriPumpInformation.super_v_mechanic'), alignment: 'left', style: 'search' },
        { text: ':', style: 'search', alignment: 'center' },
        { text: i18n.locale === 'en' ? data.super_v_mechanic : data.super_v_mechanic_bn, alignment: 'left', style: 'search' }
      ],
      [
        { text: thisObject.$t('irriPumpInformation.super_v_higher_engr'), alignment: 'left', style: 'search' },
        { text: ':', style: 'search', alignment: 'center' },
        { text: i18n.locale === 'en' ? data.super_v_higher_engr : data.super_v_higher_engr_bn, alignment: 'left', style: 'search' }
      ],
      [
        { text: thisObject.$t('irriPumpInformation.comments'), alignment: 'left', style: 'search' },
        { text: ':', style: 'search', alignment: 'center' },
        { text: i18n.locale === 'en' ? data.comments : data.comments_bn, alignment: 'left', style: 'search' }
      ]
    ]

    pdfContent.push({
      table: {
        headerRows: 0,
        widths: ['75%', '2%', '23%'],
        body: footerHead
      },
      layout: {
        hLineWidth: function (i, node) {
          return 0
        },
        vLineWidth: function (i, node) {
          return 0
        }
      }
    })

    const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
      var docDefinition = {
      content: pdfContent,
      pageSize: 'A4',
      pageOrientation: 'Portrait',
      watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
      styles: {
        th: {
          fontSize: (i18n === 'bn') ? 12 : 10,
          margin: [3, 3, 3, 3],
          bold: true
        },
        td: {
          fontSize: (i18n === 'bn') ? 12 : 10,
          margin: [3, 3, 3, 3]
        },
        search: {
          fontSize: (i18n === 'bn') ? 12 : 10,
          margin: [3, 3, 3, 3]
        },
        fertilizer: {
          margin: [0, 10, 0, 7]
        },
        fertilizerSHeader: {
            fontSize: 10,
            margin: [40, 0, 0, 0]
        },
        header: {
          fontSize: 12,
          margin: [0, 0, 0, 4]
        },
        header2: {
          fontSize: i18n.locale === 'bn' ? 14 : 12,
          margin: [0, 10, 0, 10]
        },
        header3: {
          fontSize: i18n.locale === 'bn' ? 13 : 11,
          margin: [0, 10, 0, 5],
          background: '#E9EDF4'
        },
        header4: {
          fontSize: i18n.locale === 'bn' ? 12 : 10,
          margin: [0, 10, 0, 5]
        },
        headerPort1: {
          fontSize: 10,
          margin: [0, 20, 0, 5]
        },
        headerPort: {
          fontSize: 10,
          margin: [0, 4, 0, 15]
        },
        krishi: {
          margin: [0, -5, 0, 15],
          alignment: 'center'
        },
        address: {
          fontSize: 9,
          margin: [0, -10, 0, 0]
        },
        tableSubHead: {
          margin: [0, 5, 0, 15]
        }
      }
    }
    pdfMake.createPdf(docDefinition, null, null, null).print()
    } catch (error) {
      if (error) {}
    }
    Store.commit('mutateCommonProperties', {
      loading: false
    })
}
export default {
  exportPdfDetails
}
