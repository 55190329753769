<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('irriPumpInformation.pump_report') }}</h4>
      </template>
      <template v-slot:body>
      <b-row>
        <b-col lg="6" md="6" sm="12" xs="12">
          <b-form-group
            class="row"
            label-cols-sm="3"
            :label="$t('org_pro.organization')"
            label-for="org_id"
            >
            <b-form-select
              plain
              v-model="search.org_id"
              :options="organizationList"
              id="org_id"
              >
              <template v-slot:first>
                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
              </template>
            </b-form-select>
            </b-form-group>
        </b-col>
        <b-col lg="6" md="6" sm="12" xs="12">
          <b-form-group
            class="row"
            label-cols-sm="3"
            :label="$t('org_pro_division.division')"
            label-for="division_id"
            >
            <b-form-select
              plain
              v-model="search.division_id"
              :options="divisionList"
              id="division_id"
              >
              <template v-slot:first>
                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
              </template>
            </b-form-select>
            </b-form-group>
        </b-col>
        <b-col lg="6" md="6" sm="12" xs="12">
          <b-form-group
            class="row"
            label-cols-sm="3"
            :label="$t('org_pro_district.district')"
            label-for="district_id"
            >
            <b-form-select
              plain
              v-model="search.district_id"
              :options="districtList"
              id="district_id"
              >
              <template v-slot:first>
                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
              </template>
            </b-form-select>
            </b-form-group>
        </b-col>
        <b-col lg="6" md="6" sm="12" xs="12">
          <b-form-group
            class="row"
            label-cols-sm="3"
            :label="$t('org_pro_upazilla.upazilla')"
            label-for="upazilla_id"
            >
            <b-form-select
              plain
              v-model="search.upazilla_id"
              :options="upazilaList"
              id="upazilla_id"
              >
              <template v-slot:first>
                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
              </template>
            </b-form-select>
            </b-form-group>
        </b-col>
        <b-col lg="6" md="6" sm="12" xs="12">
          <b-form-group
            class="row"
            label-cols-sm="3"
            :label="$t('org_pro_union.union')"
            label-for="union_id"
            >
            <b-form-select
              plain
              v-model="search.union_id"
              :options="unionList"
              id="union_id"
              >
              <template v-slot:first>
                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
              </template>
            </b-form-select>
            </b-form-group>
        </b-col>
        <b-co>
            <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
        </b-co>
      </b-row>
      </template>
    </iq-card>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('irriPumpInformation.pump_report_entry') }}</h4>
          </template>
          <template v-slot:headerAction>
            <export-excel
              @click="getExcelData"
              class="btn btn_add_new mr-2"
              :data="excelData"
              :title="$t('irriPumpInformation.pump_report_entry')"
              worksheet="Report Sheet"
              name="pump-report.xls">
              <i class="far fa-file-excel"></i>{{ $t('globalTrans.export_excel') }}
            </export-excel>
            <router-link to="/irrigation/pump-information/pump-report-entry-form" :class="'btn btn btn_add_new'">
              <i class="fas fa-plus"></i>{{  $t('globalTrans.add_new') }}
            </router-link>
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table thead-class="table_head" bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + 1) }}
                    </template>
                    <template v-slot:cell(division_name)="data">
                      <span class="capitalize">{{ data.item.division_name }}</span>
                    </template>
                    <template v-slot:cell(division_name_bn)="data">
                      {{ data.item.division_name_bn }}
                    </template>
                    <template v-slot:cell(district_name_bn)="data">
                      {{ data.item.district_name_bn }}
                    </template>
                    <template v-slot:cell(district_name)="data">
                      <span class="capitalize">{{ data.item.district_name }}</span>
                    </template>
                    <template v-slot:cell(org_name_bn)="data">
                      {{ data.item.org_name_bn }}
                    </template>
                    <template v-slot:cell(org_name)="data">
                      <span class="capitalize">{{ data.item.org_name }}</span>
                    </template>
                    <template v-slot:cell(pump_id)="data">
                      {{ $n(data.item.pump_id) }}
                    </template>
                    <template v-slot:cell(status)="data">
                      <b-badge pill variant="danger" v-if="data.item.status">{{ $t('globalTrans.inactive') }}</b-badge>
                      <b-badge pill variant="primary" v-else>{{ $t('globalTrans.active') }}</b-badge>
                    </template>
                    <template v-slot:cell(action)="data">
                      <!-- <b-button class="mr-1" title="View" v-b-modal.modal-detail variant=" iq-bg-success" size="sm" @click="details(data.item)"><i class="ri-eye-line m-0 "></i></b-button>
                      <router-link :to="'/irrigation/pump-information/pump-report-entry-form?id='+data.item.id" class="btn btn-info btn-sm mr-1" size="sm" @click="edit(data.item)" title="Edit"><i class="ri-ball-pen-fill m-0"></i></router-link>
                      <b-button :variant="data.item.status ? ' iq-bg-danger' : ' iq-bg-success'" size="sm" @click="remove(data.item)" title="Active/Inactive">
                          <i class="fas" :class="data.item.status ? 'fa-toggle-off' : 'fa-toggle-on'"></i>
                      </b-button> -->
                      <a href="javascript:" class="btn_table_action table_action_view" title="View" v-b-modal.modal-detail @click="details(data.item)"><i class="fas fa-eye"></i></a>
                      <a href="javascript:" class="btn_table_action table_action_edit" title="Edit" v-b-modal.modal-4 @click="edit(data.item)"><i class="ri-ball-pen-fill"></i></a>
                      <a href="javascript:" class="btn_table_action table_action_status" title="Active" v-if="data.item.status === 0" @click="remove(data.item)"><i class="fas fa-toggle-on"></i></a>
                      <a href="javascript:" class="btn_table_action table_action_toggle" title="Inactive" v-else @click="remove(data.item)"><i class="fas fa-toggle-off"></i></a>
                    </template>
                  </b-table>
                  <b-pagination
                    align="center"
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    @input="searchData"
                    />
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-modal id="modal-4" size="xl" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <p>
        <EntryForm :id="editItemId" :key="editItemId"/>
      </p>
    </b-modal>
    <!-- <b-modal id="modal-detail" size="xl" :title="detailTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
        <DetailModal :id="editItemId" :key="editItemId" :item="item"/>
    </b-modal> -->
    <b-modal id="modal-detail" size="lg" :title="detailTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <b-button @click="pdfExport" class="ml-4 btn-success water-test-pdf-button">
        {{  $t('globalTrans.print') }}
      </b-button>
      <DetailModal :id="editItemId" :key="editItemId" :item="item" ref="details"/>
    </b-modal>
  </b-container>
</template>
<script>
import EntryForm from './EntryForm'
import DetailModal from './DetailModal'
import { deepTubeWellReportList, deepTubeWellReportToggleStatus, deepTubeWellReportListAll } from '../../api/routes'
import RestApi, { irriSchemeServiceBaseUrl } from '../../../../../config/api_config'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import { mapGetters } from 'vuex'
import excel from 'vue-excel-export'
import Vue from 'vue'
Vue.use(excel)

export default {
  mixins: [ModalBaseMasterList],
  components: {
    EntryForm, DetailModal
  },
  data () {
    return {
      search: {
        org_id: '0',
        pump_id: '0',
        division_id: '0',
        district_id: '0',
        upazilla_id: '0',
        union_id: '0'
      },
      rows: [],
      excelData: [],
      allExcelData: [],
      item: '',
      pumpDropdownList: [],
      districtList: [],
      upazilaList: [],
      unionList: []
    }
  },
  computed: {
    ...mapGetters({
      authUser: 'Auth/authUser'
    }),
    organizationList: function () {
      return this.$store.state.commonObj.organizationProfileList.filter(org => org.status === 0)
    },
    currentLocale () {
      return this.$i18n.locale
    },
    formTitle () {
      return (this.testId === 0) ? this.$t('irriPumpInformation.pump_report_entry') : this.$t('irriPumpInformation.pump_report_entry') + ' ' + this.$t('globalTrans.modify')
    },
    detailTitle () {
      return this.$t('irriPumpInformation.pump_report_details')
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
          { label: this.$t('org_pro.organization'), class: 'text-center' },
          { label: this.$t('irriPumpInformation.pump_id'), class: 'text-center' },
          { label: this.$t('org_pro_division.division'), class: 'text-center' },
          { label: this.$t('org_pro_district.district'), class: 'text-center' },
          { label: this.$t('org_pro_upazilla.upazilla'), class: 'text-center' },
          { label: this.$t('org_pro_union.union'), class: 'text-center' },
          { label: this.$t('globalTrans.status'), class: 'text-center' },
          { label: this.$t('globalTrans.action'), class: 'text-center w-15' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'org_name_bn' },
          { key: 'pump_id' },
          { key: 'division_name_bn' },
          { key: 'district_name_bn' },
          { key: 'upazilla_name_bn' },
          { key: 'union_name_bn' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'org_name' },
          { key: 'pump_id' },
          { key: 'division_name' },
          { key: 'district_name' },
          { key: 'upazilla_name' },
          { key: 'union_name' },
          { key: 'status' },
          { key: 'action' }
        ]
      }
      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
      })
    },
    divisionList: function () {
      const divisions = this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
      return divisions.map((item) => {
        if (this.$i18n.locale === 'bn') {
          return { value: item.value, text: item.text_bn !== 'undefined' ? item.text_bn : '' }
        } else {
          return { value: item.value, text: item.text_en !== 'undefined' ? item.text_en : '' }
        }
      })
    }
  },
  watch: {
    'search.org_id': function (newVal, oldVal) {
      this.pumpDropdownList = this.getPumplist(newVal)
    },
    'search.division_id': function (newVal, oldVal) {
      this.districtList = this.getDistrictList(newVal)
    },
    'search.district_id': function (newVal, oldVal) {
      this.upazilaList = this.getUpazilaList(newVal)
    },
    'search.upazilla_id': function (newVal, oldVal) {
      this.unionList = this.getUnionList(newVal)
    },
    currentLocale: function (newVal, oldVal) {
        if (newVal !== oldVal) {
            if (this.search.org_id) {
              this.pumpDropdownList = this.getPumplist()
            }
            if (this.search.division_id) {
              this.districtList = this.getDistrictList()
            }
            if (this.search.district_id) {
              this.upazilaList = this.getUpazilaList()
            }
            if (this.search.upazilla_id) {
              this.unionList = this.getUnionList()
            }
        }
    }
  },
  mounted () {
    if (this.authUser.role_id === 0 && this.authUser.is_org_admin === 1) {
      this.search = Object.assign({}, this.search, {
        org_id: this.authUser.office_detail.org_id
      })
    } else if (this.authUser.role_id === 0) {
      this.search = Object.assign({}, this.search, {
        org_id: this.authUser.office_detail.org_id,
        division_id: this.authUser.office_detail.division_id,
        district_id: this.authUser.office_detail.district_id,
        upazilla_id: this.authUser.office_detail.upazilla_id
      })
    }
    this.loadData()
    this.getExcelData()
  },
  methods: {
    details (item) {
      this.editItemId = item.id
      this.item = item
    },
    searchData () {
      if (this.authUser.role_id === 0 && this.authUser.is_org_admin === 1) {
        this.search = Object.assign({}, this.search, {
          org_id: this.authUser.office_detail.org_id
        })
      } else if (this.authUser.role_id === 0) {
        this.search = Object.assign({}, this.search, {
          org_id: this.authUser.office_detail.org_id,
          division_id: this.authUser.office_detail.division_id,
          district_id: this.authUser.office_detail.district_id,
          upazilla_id: this.authUser.office_detail.upazilla_id
        })
      }
      this.loadData()
      this.getExcelData()
    },
    remove (item) {
      this.changeStatus(irriSchemeServiceBaseUrl, deepTubeWellReportToggleStatus, item)
    },
    getExcelData () {
      const params = Object.assign({}, this.search)
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(irriSchemeServiceBaseUrl, deepTubeWellReportListAll, params).then(response => {
        if (response.success) {
          this.allExcelData = this.getRelationalData(response)
          if (this.allExcelData) {
            this.generateExcelData(this.allExcelData)
          }
        } else {
          this.allExcelData = []
        }
      })
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    generateExcelData (data) {
      var serial = 0
        const rowData = data.map((item) => {
        serial += 1
        if (this.$i18n.locale === 'en') {
          return {
            'sl ': serial,
            Union: item.union_name,
            'Mouza Name': item.mouza_no,
            'J.L No': item.jl_no,
            'Plot No': item.plot_no,
            'Tubewell installation - Date of excavation': this.$d(new Date(item.install_date_of_excavation)),
            'Tubewell installation - Date of commission': this.$d(new Date(item.install_date_of_commission)),
            'Tubewell installation - Accepted participation': this.$d(new Date(item.install_accepted_participation)),
            'Tubewell rehabilitation - Date of excavation': this.$d(new Date(item.rehab_date_of_excavation)),
            'Tubewell rehabilitation - Date of commission': this.$d(new Date(item.rehab_date_of_commission)),
            'Tubewell rehabilitation - Accepted participation': this.$d(new Date(item.rehab_accepted_participation)),
            'Project Name': item.project_name,
            'Operator Name': item.operator_name,
            'Deep Tubewell Nature (Inverted/ Double/ Filter/ Normal)': item.deep_tube_well_nature,
            'Housing pipe (fit) - M. S': item.ms,
            'Housing pipe (fit) - FG': item.fg,
            'Housing pipe (fit) - UPDC': item.updc,
            'Filter (fit) - M. S': item.filter_ms,
            'Filter (fit) - FG': item.filter_fg,
            'Filter (fit) - UPDC': item.filter_updc,
            'Installed column pipe - Dia': item.dia,
            'Installed column pipe - Amount (number)': item.amount,
            'Vertical - Make O Model': item.vertical_make_o_model,
            'Vertical - Power': item.vertical_power,
            'Vertical - Unit consumption': item.vertical_unit_consumption,
            'Turbine/submersible motor - Make O Model': item.turbine_make_o_model,
            'Turbine/submersible motor - Power': item.turbine_power,
            'Turbine/submersible motor - Unit consumption': item.turbine_unit_consumption,
            'Turbine/submersible motor - Head (Feet)': item.head,
            'Turbine/submersible motor - Discharge (cusec)': item.discharge,
            'Command Area (Hector)': item.command_area,
            'Kharif-1 - Aus': item.kharif_1_aus,
            'Kharif-1 - Others': item.kharif_1_others,
            'Kharif-1 - Total': item.kharif_1_total,
            'Kharif-2 - Aman': item.kharif_2_aman,
            'Kharif-2 - Others': item.kharif_2_others,
            'Kharif-2 - Total': item.kharif_2_total,
            'Robi - Borou': item.borou,
            'Robi - Wheat': item.wheat,
            'Robi - Potato': item.potato,
            'Robi - Corn': item.corn,
            'Robi - Mustard': item.mustard,
            'Robi - Lentils': item.lentils,
            'Robi - Total': item.robi_total,
            'Beneficial farmer- Actual': item.actual,
            'Beneficial farmer - Barga': item.barga,
            'Beneficial farmer - Total': item.beneficial_farmer_total,
            'Total Used Unit (kWH) - Start Reading': item.start_reading,
            'Total Used Unit (kWH) - Ending Reading': item.end_reading,
            'Total Used Unit (kWH) - Total': item.total_uses_unit,
            'Collection Irrigation Charge - Hourly Used Unit (km/H)': item.hourly_used_unit,
            'Collection Irrigation Charge - Total Activated Hours': item.total_active_hour,
            'Collection Irrigation Charge - Hourly Irrigation Charge (Tk)': item.hourly_irri_charge,
            'Collection Irrigation Charge - Collection Irrigation Charge (Tk)': item.collected_irri_payment,
            'Collection Irrigation Charge - Unpaid Irrigation Charge (Tk)': item.unpaid_money,
            'Management Cost - Total Electricity Cost (Tk)': item.total_electricity_cost,
            'Management Cost - Operator Salary (Tk)': item.operator_salary,
            'Management Cost - Repairs Cost (Tk)': item.maintance_cost,
            'Management Cost - Other Cost (Tk)': item.other_cost,
            'Management Cost - Total Cost (Tk)': item.total_cost,
            'Management Cost - Total Income (Tk)': item.total_income,
            'Details of Ganku being useless': item.ganku_details,
            'Name of supervising mechanic / electrician / assistant mechanic': item.super_v_mechanic,
            'Name of Supervising Superintendent / Deputy Assistant Engineer / Foreman': item.super_v_higher_engr,
            Comments: item.comments
          }
        } else {
          return {
            'ক্রমিক সংখ্যা': this.$n(serial),
            ইউনিয়ন: item.union_name_bn,
            'মৌজা নাম্বার': this.$n(item.mouza_no),
            'জেএল নাম্বার': this.$n(item.jl_no),
            'দাগ নাম্বার': this.$n(item.plot_no),
            'নলকূপ স্থাপন - খননের তারিখ': this.$d(new Date(item.install_date_of_excavation)),
            'নলকূপ স্থাপন - কমিশনের তারিখ': this.$d(new Date(item.install_date_of_commission)),
            'নলকূপ স্থাপন - গৃহীত পার্টিসিপেশন': this.$d(new Date(item.install_accepted_participation)),
            'নলকূপ পুনর্বাসন - খননের তারিখ': this.$d(new Date(item.rehab_date_of_excavation)),
            'নলকূপ পুনর্বাসন - কমিশনের তারিখ': this.$d(new Date(item.rehab_date_of_commission)),
            'প্রকল্পের নাম': item.project_name,
            'অপারেটরের নাম': item.operator_name,
            'গভীর নলকূপের প্রকৃতি (ইনভার্টেড/ ডবল/ ফিল্টার/ নরমাল)': item.deep_tube_well_nature,
            'হাউজিং পাইপ (ফিট) - এম.এস': this.$n(item.ms),
            'হাউজিং পাইপ (ফিট) - এফজি': this.$n(item.fg),
            'হাউজিং পাইপ (ফিট) - ইউপিডিসি': this.$n(item.updc),
            'ফিল্টার (ফিট) - এম.এস': this.$n(item.filter_ms),
            'ফিল্টার (ফিট) - এফজি': this.$n(item.filter_fg),
            'ফিল্টার (ফিট) - ইউপিডিসি': this.$n(item.filter_updc),
            'স্থাপনকৃত কলাম পাইপ - ডায়া': this.$n(item.dia),
            'স্থাপনকৃত কলাম পাইপ - পরিমাণ (সংখ্যা)': this.$n(item.amount),
            'ভার্টিক্যাল - মেক ও মডেল': item.vertical_make_o_model,
            'ভার্টিক্যাল - ক্ষমতা (KW)': item.vertical_power,
            'ভার্টিক্যাল - ইউনিট কনজাম্পশন': item.vertical_unit_consumption,
            'টারবাইন/সাবমারসিবুল মটর - মেক ও মডেল': item.turbine_make_o_model,
            'টারবাইন/সাবমারসিবুল মটর - ক্ষমতা (KW)': this.$n(item.turbine_power),
            'টারবাইন/সাবমারসিবুল মটর - ইউনিট কনজাম্পশন': this.$n(item.turbine_unit_consumption),
            'টারবাইন/সাবমারসিবুল মটর - হেড (ফুট)': this.$n(item.head),
            'টারবাইন/সাবমারসিবুল মটর - ডিসচার্জ (কিউসেক)': this.$n(item.discharge),
            'কমান্ড এরিয়া (হেক্টর)': this.$n(item.command_area),
            'খরিপ-১ - আউস': this.$n(item.kharif_1_aus),
            'খরিপ-১ - অন্যান্য': this.$n(item.kharif_1_others),
            'খরিপ-১ - মোট': this.$n(item.kharif_1_total),
            'খরিপ-২ - আমন': this.$n(item.kharif_2_aman),
            'খরিপ-২ - অন্যান্য': this.$n(item.kharif_2_others),
            'খরিপ-২ - মোট': this.$n(item.kharif_2_total),
            'রবি - বোরো': this.$n(item.borou),
            'রবি - গম': this.$n(item.wheat),
            'রবি - আলু': this.$n(item.potato),
            'রবি - ভূট্টা': this.$n(item.corn),
            'রবি - সরিষা': this.$n(item.mustard),
            'রবি - মসুর ডাল': this.$n(item.lentils),
            'রবি - সবজি': this.$n(item.vegetables),
            'রবি - মোট': this.$n(item.robi_total),
            'উপকৃত কৃষক - প্রকৃত': this.$n(item.actual),
            'উপকৃত কৃষক - বর্গা': this.$n(item.barga),
            'উপকৃত কৃষক - মোট': this.$n(item.beneficial_farmer_total),
            'মোট ব্যবহ্রত ইউনিট (কি.ও.আঃ) - শুরু রিডিং': this.$n(item.start_reading),
            'মোট ব্যবহ্রত ইউনিট (কি.ও.আঃ) - শেষ রিডিং': this.$n(item.end_reading),
            'মোট ব্যবহ্রত ইউনিট (কি.ও.আঃ) - মোট': this.$n(item.total_uses_unit),
            'সেচচার্জ আদায়ের বিবরণ - ঘণ্টাপ্রতি ব্যবহ্রত ইউনিট (কিঃ/ঘণ্টা)': this.$n(item.hourly_used_unit),
            'সেচচার্জ আদায় - মোট চালুকৃত ঘণ্টা': this.$n(item.total_active_hour),
            'সেচচার্জ আদায় - ঘণ্টাপ্রতি সেচচার্জ (টাকা)': this.$n(item.hourly_irri_charge),
            'সেচচার্জ আদায় - আদায়যোগ্য সেচ চার্জ (টাকা)': this.$n(item.recoverable_irri_payment),
            'সেচচার্জ আদায় - আদায়কৃত সেচ চার্জ (টাকা)': this.$n(item.collected_irri_payment),
            'সেচচার্জ আদায় - অনাদায়ী সেচ চার্জ (টাকা)': this.$n(item.unpaid_money),
            'পরিচালনা ব্যয় - মোট বিদ্যুৎ খচর (টাকা)': this.$n(item.total_electricity_cost),
            'পরিচালনা ব্যয় - অপারেটর বেতন (টাকা)': this.$n(item.operator_salary),
            'পরিচালনা ব্যয় - মেরামত ব্যয় (টাকা)': this.$n(item.maintance_cost),
            'পরিচালনা ব্যয় - অন্যান্য ব্যয় (টাকা)': this.$n(item.other_cost),
            'পরিচালনা ব্যয় - মোট ব্যয় (টাকা)': this.$n(item.total_cost),
            'পরিচালনা ব্যয় - মোট আয় (টাকা)': this.$n(item.total_income),
            'সনে গনকু অকেজো থাকার বিবরণ': item.ganku_details,
            'তদারককারী মেকনিক/ ইলেক্ট্রিশিয়ান/ সহকারী মেকানিকের নাম': item.super_v_mechanic,
            'তদারককারী  উচ্চতর/ উপ-সহকারী প্রকৌশলী/ ফোরম্যানের নাম': item.super_v_higher_engr,
            মন্তব্য: item.comments
          }
        }
      })
      this.excelData = rowData
    },
    async loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      await RestApi.getData(irriSchemeServiceBaseUrl, deepTubeWellReportList, params).then(response => {
        if (response.success) {
          this.$store.dispatch('setList', this.getRelationalData(response.data))
          this.paginationData(response.data)
        } else {
          this.$store.dispatch('setList', [])
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    getRelationalData (data) {
      const organizationList = this.$store.state.commonObj.organizationProfileList
      const divisionList = this.$store.state.commonObj.divisionList
      const districtList = this.$store.state.commonObj.districtList
      const upazilaList = this.$store.state.commonObj.upazilaList
      const unionList = this.$store.state.commonObj.unionList
      return data.data.map(item => {
        const orgObject = organizationList.find(oganization => oganization.value === item.org_id)
        const divisionObject = divisionList.find(division => division.value === item.division_id)
        const districtObject = districtList.find(district => district.value === item.district_id)
        const upazilaObject = upazilaList.find(upazilla => upazilla.value === item.upazilla_id)
        const unionObject = unionList.find(union => union.value === item.union_id)
        const orgData = {
          org_logo: orgObject ? orgObject.loto : '',
          org_name: orgObject ? orgObject.text_en : '',
          org_name_bn: orgObject ? orgObject.text_bn : ''
        }
        const divisionData = {
          division_name: divisionObject.text_en,
          division_name_bn: divisionObject.text_bn
        }
        const districtData = {
          district_name: districtObject.text_en,
          district_name_bn: districtObject.text_bn
        }
        const upazillaData = {
          upazilla_name: upazilaObject.text_en,
          upazilla_name_bn: upazilaObject.text_bn
        }
        const unionData = {
          union_name: unionObject.text_en,
          union_name_bn: unionObject.text_bn
        }
        return Object.assign({}, item, orgData, divisionData, districtData, upazillaData, unionData)
      })
    },
    getPumplist (orgId) {
        this.pumpInformationList = this.$store.state.IrriConfig.commonObj.pumpInfoList.filter(pumpInfo => pumpInfo.org_id === orgId && pumpInfo.status === 0)
        return this.pumpInformationList.map((obj, index) => {
          if (this.$i18n.locale === 'bn') {
            return { value: obj.id, text: obj.pump_id }
          } else {
            return { value: obj.id, text: obj.pump_id }
          }
        })
    },
    getDistrictList (divisionId = null) {
      const districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0)
      if (divisionId) {
        return districtList.filter(district => district.division_id === divisionId)
      }
      return districtList
    },
    getUpazilaList (districtId = null) {
      const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)
      if (districtId) {
        return upazilaList.filter(upazila => upazila.district_id === districtId)
      }
      return upazilaList
    },
    getUnionList (upazillaId = null) {
      const unionList = this.$store.state.commonObj.unionList.filter(item => item.status === 0)
      if (upazillaId) {
        return unionList.filter(union => union.upazilla_id === upazillaId)
      }
      return unionList
    },
    getProjectlist (orgId) {
      this.projectList = this.$store.state.IrriConfig.commonObj.projectList.filter(project => project.org_id === orgId && project.status === 0)
    },
    pdfExport () {
      this.$refs.details.pdfExport()
    }
  }
}
</script>
